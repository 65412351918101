import React, { useEffect, useState, useRef } from "react";
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Card,
    CardContent,
    Button,
    useMediaQuery,
    Box,
    CircularProgress,
    ClickAwayListener,
    Tooltip,
    IconButton,
    Divider,
    Rating,
    FormControlLabel,
    Checkbox,
    Link,
    Alert,
    InputAdornment,
    Snackbar
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AdjustIcon from '@mui/icons-material/Adjust';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PostBooking from "./PostBooking";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const Booking = () => {
    const location = useLocation();
    const { id } = useParams();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const [artist, setArtist] = useState(location.state?.artist || null);
    const [date, setDate] = useState(null);
    const [period, setPeriod] = useState(location.state?.period || '');
    const [artistTypeLabel, setArtistTypeLabel] = useState("");
    const [artistTypeLabel2, setArtistTypeLabel2] = useState("");
    const [loading, setLoading] = useState(true);
    const { user, isAuthenticated } = useAuth0();
    const [contractor, setContractor] = useState(null);
    const datePickerRef = useRef(null);
    const [periods, setPeriods] = useState([]);
    const [bookingStatus, setBookingStatus] = useState([]);
    const [openTooltipBookingOwner, setOpenTooltipBookingOwner] = useState(false);
    const [openTooltipAddressInfo, setOpenTooltipAddressInfo] = useState(false);
    const [openTooltipArtistPhoneNumber, SetOpenTooltipArtistPhoneNumber] = useState(false);
    const [openTooltipDatePeriod, SetOpenTooltipDatePeriod] = useState(false);
    const [OpenTooltipPrice, SetOpenTooltipPrice] = useState(false);
    const [bookingComplete, setBookingComplete] = useState(false);
    const [whatsappLink, setWhatsappLink] = useState('');
    const [isReserving, setIsReserving] = useState(false);
    const [authorizeData, setAuthorizeData] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;

    const [bookingError, setBookingError] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const localeText = {
        okButtonLabel: 'OK',
        cancelButtonLabel: 'Cancelar',
        clearButtonLabel: 'Limpar',
        todayButtonLabel: 'Hoje',
        datePickerToolbarTitle: 'Selecionar data',
        datePickerDefaultToolbarTitle: 'Selecionar data',
    };

    const handleDateChange = (newValue) => {
        setDate(newValue);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (location.state?.date) {
            const dayjs = require('dayjs');
            setDate(dayjs(location.state.date));
        }
    }, [location.state?.date]);

    const handleTooltipBookingOwnerClose = () => {
        setOpenTooltipBookingOwner(false);
    };

    const handleTooltipBookingOwnerOpen = () => {
        setOpenTooltipBookingOwner(true);
    };

    const handleTooltipAddressInfoClose = () => {
        setOpenTooltipAddressInfo(false);
    };

    const handleTooltipAddressInfoOpen = () => {
        setOpenTooltipAddressInfo(true);
    };

    const handleTooltipArtistPhoneNumberClose = () => {
        SetOpenTooltipArtistPhoneNumber(false);
    };

    const handleTooltipArtistPhoneNumberOpen = () => {
        SetOpenTooltipArtistPhoneNumber(true);
    };

    const handleTooltipDatePeriodClose = () => {
        SetOpenTooltipDatePeriod(false);
    };

    const handleTooltipDatePeriodOpen = () => {
        SetOpenTooltipDatePeriod(true);
    };

    const handleTooltipPriceClose = () => {
        SetOpenTooltipPrice(false);
    };

    const handleTooltipPriceOpen = () => {
        SetOpenTooltipPrice(true);
    };

    const handleAuthorizeDataChange = (event) => {
        setAuthorizeData(event.target.checked);
    };

    const handleAcceptTermsChange = (event) => {
        setAcceptTerms(event.target.checked);
    };

    useEffect(() => {
        const fetchUserArtist = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/profile/getuser?auth0_sub_id=${id}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authKey: apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch user artist");
                }
                const result = await response.json();
                setArtist(result.user);
            } catch (error) {
                console.error("Error fetching user artist:", error);
            }
        };

        const enforceLoadingDelay = setTimeout(() => setLoading(false), 3000);

        if (!artist) {
            fetchUserArtist().finally(() => {
                clearTimeout(enforceLoadingDelay);
                setTimeout(() => setLoading(false), 3000);
            });
        } else {
            setTimeout(() => setLoading(false), 3000);
        }

        return () => clearTimeout(enforceLoadingDelay);
    }, [artist, railwayAPIURL, apiAuthKey, id]);

    useEffect(() => {
        const fetchUserContractor = async () => {
            if (!user || !isAuthenticated || !railwayAPIURL || !apiAuthKey) return;

            try {
                const response = await fetch(`${railwayAPIURL}/api/v1/profile/getuser?auth0_sub_id=${user.sub}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authKey': apiAuthKey,
                    },
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    const errorMessage = errorData?.error || response.statusText;
                    throw new Error(`Failed to fetch user visitor: ${errorMessage}`);
                }

                const result = await response.json();
                const contractor = result.user;
                setContractor(contractor);

            } catch (error) {
                console.error('Error fetching user visitor:', error);
            }
        };

        if (user && isAuthenticated && railwayAPIURL && apiAuthKey) {
            fetchUserContractor();
        }
    }, [railwayAPIURL, apiAuthKey, user, isAuthenticated]);

    useEffect(() => {
        if (artist && artist.artist_type) {
            const newArtistTypeLabel = `${artist.artist_type.includes("band")
                ? "a banda "
                : artist.artist_type.includes("musician")
                    ? "o músico "
                    : artist.artist_type.includes("dj") && "o dj "
                }`;
            setArtistTypeLabel(newArtistTypeLabel);

            const newArtistTypeLabel2 = `${artist.artist_type.includes("band")
                ? "Banda"
                : artist.artist_type.includes("musician")
                    ? "Músico "
                    : artist.artist_type.includes("dj") && "Dj "
                }`;
            setArtistTypeLabel2(newArtistTypeLabel2);
        }
    }, [artist]);

    useEffect(() => {
        if (contractor) {
            console.log("Contractor:", contractor);
        }
    }, [contractor]);

    useEffect(() => {
        if (artist) {
            console.log("Artist:", artist);
        }
    }, [artist]);

    useEffect(() => {
        console.log("date:", date);
        console.log("period:", period);

    }, [date, period]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = "Se você decidir sair desta página, fique ciente que poderá perder todas as informações da sua reserva e talvez terá que começar o processo novamente.";
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const fetchPeriods = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/dictionary/getdictionary?allPeriods=true`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'authKey': apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch periods');
                }
                const result = await response.json();

                const periodsList = result.data[0].period;
                setPeriods(periodsList || []);
            } catch (error) {
                console.error('Error fetching period:', error);
            }
        };

        fetchPeriods();
    }, [railwayAPIURL, apiAuthKey, period]);

    useEffect(() => {
        const fetchBookingStatus = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/dictionary/getdictionary?allBookingStatus=true`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'authKey': apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch booking status');
                }
                const result = await response.json();

                const bookingStatus = result.data[0].booking_status;
                setBookingStatus(bookingStatus || []);
            } catch (error) {
                console.error('Error fetching booking status:', error);
            }
        };

        fetchBookingStatus();
    }, [railwayAPIURL, apiAuthKey, bookingStatus]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleBooking = async () => {
        if (!user || !isAuthenticated || !railwayAPIURL || !apiAuthKey || !authorizeData || !acceptTerms) {
            console.error('Missing required data for booking');
            return;
        }

        setIsReserving(true);
        setBookingError(null);

        const headers = new Headers();
        headers.append("authKey", apiAuthKey);
        headers.append("Content-Type", "application/json");

        const formattedDate = date ? date.format('YYYY-MM-DD') : '';

        const bookingData = JSON.stringify({
            contractor_auth0_sub_id: user.sub,
            artist_auth0_sub_id: id,
            booking_date: formattedDate,
            booking_period: period,
            booking_status: bookingStatus[0],
            music_styles: artist?.music_styles,
            members_number: artist?.members_number,
            price: artist?.price
        });

        const requestOptions = {
            method: "POST",
            headers: headers,
            body: bookingData,
            redirect: "follow"
        };

        try {
            const response = await fetch(`${railwayAPIURL}/api/v1/reservation/createbooking`, requestOptions);

            if (!response.ok) {
                const errorData = await response.json();

                if (response.status === 400 && errorData.msg === "Você atingiu o limite máximo de 5 reservas ativas. Para fazer uma nova reserva, complete ou cancele uma reserva existente.") {
                    setBookingError(errorData.msg);
                    setOpenSnackbar(true);
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                return;
            }

            await response.json();
            const mobilePhone = artist?.mobile_phone?.replace(/\D/g, '');
            setWhatsappLink(`https://wa.me/${mobilePhone}`);
            await new Promise(resolve => setTimeout(resolve, 4000));
            setBookingComplete(true);

        } catch (error) {
            console.error("Error creating booking:", error);
            setBookingError("Ocorreu um erro ao criar a reserva. Tente novamente.");
            setOpenSnackbar(true);
        } finally {
            setIsReserving(false);
        }
    };



    const formatDateToBrazilian = (dateValue) => {
        if (!dateValue) return '';

        if (dateValue && typeof dateValue === 'object' && dateValue.format) {
            return dateValue.format('DD/MM/YYYY');
        }

        if (typeof dateValue === 'string') {
            const dateParts = dateValue.split('-');
            if (dateParts.length !== 3) return dateValue;
            return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        }

        return '';
    };

    if (loading) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 30 }}>
                <CircularProgress sx={{ color: "#4F46E5" }} />
                <Typography sx={{ mt: 2 }} variant="h6" color="textSecondary">
                    Processando sua reserva...
                </Typography>
            </Box>
        );
    }

    if (isReserving) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 30 }}>
                <CircularProgress sx={{ color: "#4F46E5" }} />
                <Typography sx={{ mt: 2 }} variant="h6" color="textSecondary">
                    Efetuando a sua reserva...
                </Typography>
            </Box>
        );
    }

    if (bookingComplete) {
        return <PostBooking whatsappLink={whatsappLink} />;
    }

    if (!artist) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 30 }}>
                <Typography variant="h6" color="error">
                    Ops! Ocorreu um erro aqui. Tente novamente.
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: isMobile ? Math.min(10, 60) : Math.min(10, 30),
                textAlign: isMobile ? "center" : "left",
            }}
        >
            <Grid container spacing={3} padding={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Legal! Você escolheu {artistTypeLabel}
                        <Box component="span" sx={{ color: "#4F46E5", fontWeight: "bold" }}>
                            {artist.artist_name}
                        </Box>. Revise as informações a seguir para finalizar sua reserva.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Card
                        elevation={2}
                        sx={{
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '2px',
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0))',
                                borderTopLeftRadius: '1px',
                                borderTopRightRadius: '1px',
                            }
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Quem é titular da reserva?
                            </Typography>
                            <Typography variant="subtitle" gutterBottom>
                                É o responsável pela contratação do artista para o evento
                            </Typography>
                            <ClickAwayListener onClickAway={handleTooltipBookingOwnerClose}>
                                <Tooltip
                                    PopperProps={{ disablePortal: true }}
                                    onClose={handleTooltipBookingOwnerClose}
                                    open={openTooltipBookingOwner}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Para editar qualquer informação do titular, você precisa editar suas informações de perfil"
                                >
                                    <IconButton
                                        size="small"
                                        onClick={handleTooltipBookingOwnerOpen}
                                        sx={{ ml: 1 }}
                                    >
                                        <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                            <Typography variant="h6"
                                sx={{
                                    mt: 2
                                }}
                            >
                                Titular
                            </Typography>
                            <Grid container spacing={2} marginTop={1}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Identificador do titular"
                                        variant="outlined"
                                        placeholder="Identificador do titular"
                                        value={contractor?.auth0_sub_id || ''}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Nome Completo"
                                        variant="outlined"
                                        placeholder="Nome completo do titular"
                                        value={contractor?.name || ''}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Perfil"
                                        variant="outlined"
                                        placeholder="Perfil do titular"
                                        value={contractor?.role === "contractor" ? "Contratante" : contractor?.role || ""}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="E-mail"
                                        variant="outlined"
                                        placeholder="E-mail de usuário do titular"
                                        value={contractor?.email || ""}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card
                        elevation={2}
                        sx={{
                            mt: 3,
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '2px',
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0))',
                                borderTopLeftRadius: '1px',
                                borderTopRightRadius: '1px',
                            }
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Quem você está contratando?
                            </Typography>
                            <Typography variant="subtitle" gutterBottom>
                                Artista selecionado por você
                            </Typography>
                            <Typography variant="h6"
                                sx={{
                                    mt: 2
                                }}
                            >
                                Artista Contratado
                            </Typography>
                            <Grid container spacing={2} marginTop={1}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Identificador do artista"
                                        variant="outlined"
                                        placeholder="Identificador do artisa"
                                        value={artist?.auth0_sub_id || ''}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label='Perfil do artista'
                                        variant="outlined"
                                        placeholder='Perfil do artista'
                                        value={artistTypeLabel2}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label={`Nome d${artistTypeLabel}`}
                                        variant="outlined"
                                        placeholder={`Nome ${artistTypeLabel}`}
                                        value={artist?.artist_name || ''}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Nome do artista responsável"
                                        variant="outlined"
                                        placeholder="Nome do artista responsável"
                                        value={artist?.name || ""}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="E-mail do artista responsável"
                                        variant="outlined"
                                        placeholder="E-mail do artista responsável"
                                        value={artist?.email || ""}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card
                        elevation={2}
                        sx={{
                            mt: 3,
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '2px',
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0))',
                                borderTopLeftRadius: '1px',
                                borderTopRightRadius: '1px',
                            }
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Onde o artista irá tocar?
                            </Typography>
                            <Typography variant="subtitle" gutterBottom>
                                Endereço e local designado para o show do artista
                            </Typography>
                            <ClickAwayListener onClickAway={handleTooltipAddressInfoClose}>
                                <Tooltip
                                    PopperProps={{ disablePortal: true }}
                                    onClose={handleTooltipAddressInfoClose}
                                    open={openTooltipAddressInfo}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Para editar qualquer informação de endereço e local do titular, você precisa editar suas informações de perfil"
                                >
                                    <IconButton
                                        size="small"
                                        onClick={handleTooltipAddressInfoOpen}
                                        sx={{ ml: 1 }}
                                    >
                                        <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                            <Typography variant="h6"
                                sx={{
                                    mt: 2
                                }}
                            >
                                Endereço e Local
                            </Typography>
                            <Grid container spacing={2} marginTop={1}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Nome do Estabelecimento"
                                        variant="outlined"
                                        placeholder="Nome do Estabelecimento"
                                        value={contractor?.establishment_name || ''}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Endereço"
                                        variant="outlined"
                                        placeholder="Endereço"
                                        value={contractor?.address || ''}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label='Número'
                                        variant="outlined"
                                        placeholder='Número'
                                        value={contractor?.address_number || ''}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Complemento"
                                        variant="outlined"
                                        placeholder="Complemento"
                                        value={contractor?.complement || ''}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="CEP"
                                        variant="outlined"
                                        placeholder="CEP"
                                        value={contractor?.zipcode || ""}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="País"
                                        variant="outlined"
                                        placeholder="País"
                                        value={contractor?.country || ""}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Estado"
                                        variant="outlined"
                                        placeholder="Estado"
                                        value={contractor?.province || ""}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Cidade"
                                        variant="outlined"
                                        placeholder="Cidade"
                                        value={contractor?.city || ""}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Zona"
                                        variant="outlined"
                                        placeholder="Zona"
                                        value={contractor?.zone || ""}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card
                        elevation={2}
                        sx={{
                            mt: 3,
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '2px',
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0))',
                                borderTopLeftRadius: '1px',
                                borderTopRightRadius: '1px',
                            }
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Quando o artista irá tocar?
                            </Typography>
                            <Typography variant="subtitle" gutterBottom>
                                Data que o artista irá tocar
                            </Typography>
                            <ClickAwayListener onClickAway={handleTooltipDatePeriodClose}>
                                <Tooltip
                                    PopperProps={{ disablePortal: true }}
                                    onClose={handleTooltipDatePeriodClose}
                                    open={openTooltipDatePeriod}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="Algumas datas e períodos podem não estar disponíveis devido a reservas já realizadas"
                                >
                                    <IconButton
                                        size="small"
                                        onClick={handleTooltipDatePeriodOpen}
                                        sx={{ ml: 1 }}
                                    >
                                        <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="pt-br"
                                localeText={localeText}
                            >
                                <ClickAwayListener onClickAway={handleClickOutside}>
                                    <Box ref={datePickerRef}>
                                        <DatePicker
                                            label="Data do evento"
                                            value={date}
                                            onChange={handleDateChange}
                                            disablePast
                                            open={isOpen}
                                            onOpen={() => setIsOpen(true)}
                                            onClose={() => setIsOpen(false)}
                                            sx={{
                                                width: '100%',
                                                '& .MuiInputBase-input': {
                                                    color: '#614ed9',
                                                },
                                                '& .MuiPickersDay-root.Mui-selected, & .MuiPickersDay-root.Mui-selected:focus, & .MuiPickersDay-root.Mui-selected:hover, & .MuiPickersDay-root.MuiPickersDay-today.Mui-selected': {
                                                    backgroundColor: '#614ed9 !important',
                                                    color: 'white !important',
                                                },
                                                '& .MuiPickersDay-root:not(.Mui-selected):hover': {
                                                    backgroundColor: '#614ed933',
                                                },
                                                '& .MuiPickersDay-today:not(.Mui-selected)': {
                                                    borderColor: '#614ed9',
                                                    color: '#614ed9',
                                                },
                                                '& .MuiButton-root': {
                                                    color: '#614ed9',
                                                },
                                                '& .MuiPickersCalendarHeader-label': {
                                                    color: '#614ed9',
                                                },
                                                '& .MuiPickersDay-daySelected, & .MuiPickersDay-daySelected:hover, & .MuiPickersDay-daySelected:focus': {
                                                    backgroundColor: '#614ed9 !important',
                                                    color: 'white !important',
                                                },
                                                '& .MuiPickersDay-dayOutsideMonth': {
                                                    color: '#614ed980',
                                                },
                                                '& .MuiPickersArrowSwitcher-button': {
                                                    color: '#614ed9',
                                                },
                                                '@media (max-width: 600px)': {
                                                    '& .MuiPickersDay-root.Mui-selected, & .MuiPickersDay-root.Mui-selected:focus, & .MuiPickersDay-root.Mui-selected:hover, & .MuiPickersDay-root.MuiPickersDay-today.Mui-selected': {
                                                        backgroundColor: '#614ed9 !important',
                                                        color: 'white !important',
                                                    },
                                                },
                                            }}
                                            slots={{
                                                textField: (params) => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        onClick={() => setIsOpen(true)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <>
                                                                    {isMobile && (
                                                                        <InputAdornment position="end">
                                                                            <CalendarTodayIcon sx={{ color: '#614ed9' }} />
                                                                        </InputAdornment>
                                                                    )}
                                                                    {params.InputProps.endAdornment}
                                                                </>
                                                            ),
                                                        }}
                                                    />
                                                ),
                                            }}
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'preventOverflow',
                                                            options: {
                                                                altAxis: true,
                                                                tether: false,
                                                            },
                                                        },
                                                    ],
                                                    sx: {
                                                        '&.MuiPickersPopper-root': {
                                                            position: 'fixed !important',
                                                            top: '50% !important',
                                                            left: '50% !important',
                                                            transform: 'translate(-15%, -15%) !important',
                                                        },
                                                        '& .MuiPickersDay-root.Mui-selected, & .MuiPickersDay-root.Mui-selected:focus, & .MuiPickersDay-root.Mui-selected:hover, & .MuiPickersDay-root.MuiPickersDay-today.Mui-selected': {
                                                            backgroundColor: '#614ed9 !important',
                                                            color: 'white !important',
                                                        },
                                                        '@media (max-width: 600px)': {
                                                            '& .MuiPickersDay-root.Mui-selected, & .MuiPickersDay-root.Mui-selected:focus, & .MuiPickersDay-root.Mui-selected:hover, & .MuiPickersDay-root.MuiPickersDay-today.Mui-selected': {
                                                                backgroundColor: '#614ed9 !important',
                                                                color: 'white !important',
                                                            },
                                                        },
                                                    },
                                                },
                                                actionBar: {
                                                    sx: {
                                                        '& .MuiButton-root': {
                                                            color: '#614ed9',
                                                        },
                                                    },
                                                },
                                                dialog: {
                                                    sx: {
                                                        '& .MuiPickersDay-root.Mui-selected, & .MuiPickersDay-root.Mui-selected:focus, & .MuiPickersDay-root.Mui-selected:hover, & .MuiPickersDay-root.MuiPickersDay-today.Mui-selected': {
                                                            backgroundColor: '#614ed9 !important',
                                                            color: 'white !important',
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </ClickAwayListener>
                            </LocalizationProvider>

                            <Typography variant="subtitle"
                                sx={{
                                    mt: 2
                                }}
                            >
                                Perído do dia que o artista irá tocar
                            </Typography>
                            <Typography variant="h6"
                                sx={{
                                    mt: 2
                                }}
                            >
                                <TextField
                                    select
                                    label="Período"
                                    value={period || ''}
                                    onChange={(e) => setPeriod(e.target.value)}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: 'transparent',
                                        '& .MuiInputBase-input': {
                                            color: '#2812B3',
                                        },
                                    }}
                                >
                                    {periods.length > 0 ? (
                                        periods.map((periodsOption) => (
                                            <MenuItem key={periodsOption} value={periodsOption}>
                                                {periodsOption}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            Períodos não disponíveis
                                        </MenuItem>
                                    )}
                                </TextField>
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card
                        elevation={2}
                        sx={{
                            mt: 3,
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '2px',
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0))',
                                borderTopLeftRadius: '1px',
                                borderTopRightRadius: '1px',
                            }
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Número de contato para acionamento do artista
                            </Typography>
                            <Typography variant="subtitle" gutterBottom>
                                Número de contato do artista
                            </Typography>
                            <ClickAwayListener onClickAway={handleTooltipArtistPhoneNumberClose}>
                                <Tooltip
                                    PopperProps={{ disablePortal: true }}
                                    onClose={handleTooltipArtistPhoneNumberClose}
                                    open={openTooltipArtistPhoneNumber}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title="O número de contato do artista só será liberado quando a reserva for concluida."
                                >
                                    <IconButton
                                        size="small"
                                        onClick={handleTooltipArtistPhoneNumberOpen}
                                        sx={{ ml: 1 }}
                                    >
                                        <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                                    </IconButton>
                                </Tooltip>
                            </ClickAwayListener>
                            <Typography variant="h6"
                                sx={{
                                    mt: 2,
                                    mb: 2
                                }}
                            >
                                {artist?.mobile_phone ? artist.mobile_phone.replace(/^(\+\d{2})\s\(\d{2}\)\s\d{5}/, '+** (**) *****') : ''}
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card
                        elevation={2}
                        sx={{
                            mt: 3,
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '2px',
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0))',
                                borderTopLeftRadius: '1px',
                                borderTopRightRadius: '1px',
                            }
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Para onde enviamos a notificação de confirmação da reserva?
                            </Typography>
                            <Typography variant="subtitle" gutterBottom>
                                Enviaremos uma notificação de confirmação da reserva para esta conta
                            </Typography>
                            <Typography variant="h6"
                                sx={{
                                    mt: 2
                                }}
                            >
                                {contractor.nickname}
                            </Typography>
                            <Typography variant="body2"
                                sx={{
                                    mt: 2
                                }}
                            >
                                Para mais informações, consulte as {" "}
                                <Link
                                    href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#614ed9'
                                    }}
                                >políticas de privacidade</Link>.
                            </Typography>
                        </CardContent>
                    </Card>

                    {!isMobile && (
                        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={authorizeData}
                                        onChange={handleAuthorizeDataChange}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#614ed9',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        Eu autorizo a Conexxa a {" "}
                                        <Link
                                            href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#614ed9'
                                            }}
                                        >
                                            armazenar e tratar meus dados de forma consistente.
                                        </Link>
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={acceptTerms}
                                        onChange={handleAcceptTermsChange}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#614ed9',
                                            },
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        Li e aceito a{" "}
                                        <Link
                                            href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#614ed9'
                                            }}
                                        >
                                            política de privacidade
                                        </Link>{" "}
                                        e{" "}
                                        <Link
                                            href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#614ed9'
                                            }}
                                        >
                                            política de cancelamento.
                                        </Link>
                                    </Typography>
                                }
                            />
                            {!isReserving && authorizeData && acceptTerms && (!date || !period) && (
                                <Alert
                                    severity="warning"
                                    sx={{ mt: 2, mb: 1 }}
                                >
                                    A data e o período da reserva ainda não foram definidos.
                                </Alert>
                            )}
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Button
                                    variant="contained"
                                    onClick={handleBooking}
                                    disabled={isReserving || !authorizeData || !acceptTerms || !date || !period}
                                    sx={{
                                        borderRadius: 2,
                                        backgroundColor: '#614ed9',
                                        '&:hover': {
                                            backgroundColor: '#4a3ab8',
                                        },
                                    }}
                                >
                                    {isReserving ? 'Reservando...' : 'Reservar'}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card
                        style={{ marginTop: 2 }}
                        elevation={2}
                        sx={{
                            mt: 3,
                            position: 'relative',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                height: '2px',
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0))',
                                borderTopLeftRadius: '1px',
                                borderTopRightRadius: '1px',
                            }
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Detalhes da reserva
                            </Typography>
                            <Typography variant="h6" align="left">{artistTypeLabel2} - {artist?.artist_name}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Rating sx={{ mr: 1 }} name="read-only" value={Math.floor(artist?.rating)} readOnly />
                                <Typography>({artist?.rating.toFixed(1)})</Typography>
                            </Box>
                            <Typography align="left">Responsável {" "}
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'inline-block',
                                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                        borderRadius: 1,
                                        px: 1,
                                        py: 0.5,
                                        mb: 1,
                                    }}
                                >
                                    {artist?.name}
                                </Box>
                            </Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                Com{" "}
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                        borderRadius: 1,
                                        px: 1,
                                        py: 0.5,
                                        mx: 1,
                                    }}
                                >
                                    {artist?.members_number}
                                </Box>
                                <Groups2OutlinedIcon sx={{ mr: 1 }} />
                                integrantes
                            </Typography>
                            <Grid container spacing={2} sx={{ mt: 0.5 }}>
                                <Grid item xs={1} sx={{ position: 'relative' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                                        <AdjustIcon sx={{ mb: 0 }} />
                                        <Box sx={{ flexGrow: 1, width: 2, backgroundColor: 'grey.300' }} />
                                        <AdjustIcon sx={{ mt: 0 }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography align="left">Vem de:</Typography>
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'inline-block',
                                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                            borderRadius: 1,
                                            px: 1,
                                            py: 0.5,
                                            mb: 2,
                                        }}
                                    >
                                        {artist?.zone}, {artist?.city}, {artist?.province}, {artist?.country}
                                    </Box>
                                    <Typography align="left">Tocar em:</Typography>
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'inline-block',
                                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                            borderRadius: 1,
                                            px: 1,
                                            py: 0.5,
                                        }}
                                    >
                                        {contractor?.establishment_name}, {contractor?.address}, {contractor?.address_number}, {contractor?.zone}, {contractor?.zipcode}, {contractor?.city}, {contractor?.province}, {contractor?.country}
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" width="100%" mt={2}>
                                {date && !isNaN(new Date(date)) ? (
                                    <Grid item>
                                        <Typography whiteSpace="nowrap" sx={{ display: 'flex', alignItems: 'center' }}>
                                            No dia <CalendarMonthOutlinedIcon sx={{ ml: 1 }} />
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <CalendarMonthOutlinedIcon sx={{ ml: 1 }} />
                                )
                                }
                                <Grid item xs>
                                    {date && !isNaN(new Date(date)) ? (
                                        <Box
                                            sx={{
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                                borderRadius: 1,
                                                px: 1,
                                                py: 0.5,
                                                ml: 1,
                                            }}
                                        >
                                            <Typography>{formatDateToBrazilian(date)}</Typography>
                                        </Box>
                                    ) : (
                                        <Alert
                                            sx={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                boxSizing: 'border-box',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                ml: 1,
                                            }}
                                            severity="warning"
                                        >
                                            A data de reserva ainda não foi definida, por favor escolha um dia, mês e ano.
                                        </Alert>
                                    )}
                                </Grid>
                            </Grid>


                            <Grid container alignItems="center" width="100%" mt={2}>
                                {period ? (
                                    <Grid item>
                                        <Typography whiteSpace="nowrap" sx={{ display: 'flex', alignItems: 'center' }}>
                                            De <AccessTimeOutlinedIcon sx={{ ml: 1 }} />
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <AccessTimeOutlinedIcon sx={{ ml: 1 }} />
                                )}
                                <Grid item xs>
                                    {period ? (
                                        <Box sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
                                            {(() => {
                                                let startTime, endTime;
                                                switch (period) {
                                                    case 'Manhã':
                                                        startTime = '06:00';
                                                        endTime = '11:59';
                                                        break;
                                                    case 'Tarde':
                                                        startTime = '12:00';
                                                        endTime = '17:59';
                                                        break;
                                                    case 'Noite':
                                                        startTime = '18:00';
                                                        endTime = '23:59';
                                                        break;
                                                    default:
                                                        return <Typography component="span">{period}</Typography>;
                                                }
                                                return (
                                                    <>
                                                        <Box
                                                            sx={{
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                                                borderRadius: 1,
                                                                px: 1,
                                                                py: 0.5,
                                                                mr: 1,
                                                            }}
                                                        >
                                                            <Typography component="span">{startTime}</Typography>
                                                        </Box>
                                                        <Typography component="span" sx={{ mr: 1 }}>
                                                            às
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                                                borderRadius: 1,
                                                                px: 1,
                                                                py: 0.5,
                                                            }}
                                                        >
                                                            <Typography component="span">{endTime}</Typography>
                                                        </Box>
                                                    </>
                                                );
                                            })()}
                                        </Box>
                                    ) : (
                                        <Alert
                                            sx={{
                                                ml: 1,
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                            severity="warning"
                                        >
                                            O período da reserva ainda não foi definida, por favor escolha um período para continuar a sua reserva.
                                        </Alert>
                                    )}
                                </Grid>
                            </Grid>


                            <Divider sx={{ mt: 2, mb: 2 }} />

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                                <Typography sx={{ textAlign: 'left' }}>
                                    Por
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'right' }}
                                >
                                    {period && date ? (
                                        <>R$ {artist?.price}</>
                                    ) : (
                                        <>R$ **********</>
                                    )}
                                    <ClickAwayListener onClickAway={handleTooltipPriceClose}>
                                        <Tooltip
                                            PopperProps={{ disablePortal: true }}
                                            onClose={handleTooltipPriceClose}
                                            open={OpenTooltipPrice}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title="Impostos e taxas inclusos"
                                        >
                                            <IconButton
                                                size="small"
                                                onClick={handleTooltipPriceOpen}
                                                sx={{ ml: 1 }}
                                            >
                                                <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </ClickAwayListener>
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>

                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                            {bookingError}
                        </Alert>
                    </Snackbar>

                    {isMobile && (
                        <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={authorizeData}
                                        onChange={handleAuthorizeDataChange}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#614ed9',
                                            },
                                        }}
                                    />}
                                label={
                                    <Typography variant="body2">
                                        Eu autorizo a Conexxa a {" "}
                                        <Link
                                            href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#614ed9'
                                            }}
                                        >
                                            armazenar e tratar meus dados de forma consistente.
                                        </Link>
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={acceptTerms}
                                        onChange={handleAcceptTermsChange}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#614ed9',
                                            },
                                        }}
                                    />}
                                label={
                                    <Typography variant="body2">
                                        Li e aceito a{" "}
                                        <Link
                                            href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#614ed9'
                                            }}
                                        >
                                            política de privacidade
                                        </Link>{" "}
                                        e{" "}
                                        <Link
                                            href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#614ed9'
                                            }}
                                        >
                                            política de cancelamento.
                                        </Link>
                                    </Typography>
                                }
                            />
                            {!isReserving && authorizeData && acceptTerms && (!date || !period) && (
                                <Alert
                                    severity="warning"
                                    sx={{ mt: 2, mb: 1 }}
                                >
                                    A data e o período da reserva ainda não foram definidos.
                                </Alert>
                            )}
                            <Button
                                variant="contained"
                                onClick={handleBooking}
                                disabled={isReserving || !authorizeData || !acceptTerms || !date || !period}
                                sx={{
                                    backgroundColor: '#614ed9',
                                    '&:hover': {
                                        backgroundColor: '#4a3ab8',
                                    },
                                }}
                            >
                                {isReserving ? 'Reservando...' : 'Reservar'}
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );

};

export default Booking;
