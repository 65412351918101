import React, { useState, useEffect } from "react";
import { Tooltip, Avatar, TextField, Collapse, Link, Button, Box, Typography, Card, CardContent, Grid, Rating, Alert, useMediaQuery, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Modal, IconButton, CircularProgress } from "@mui/material";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AdjustIcon from '@mui/icons-material/Adjust';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import QuiltedImageList from '../utils/QuiltedImageList';
import CloseIcon from '@mui/icons-material/Close';
import BookingActions from "../utils/BookingActions";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';



const BookingScheduledCard = (
    {
        bookingID,
        artistID,
        contractorID,
        period,
        date,
        bookingStatus,
        bookingMembersNumber,
        bookingPrice,
        userPicture,
        userRole,
        hasCommented,
        hasRated
    }
) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [artistTypeLabel2, setArtistTypeLabel2] = useState("");
    const [artistTypeLabel3, setArtistTypeLabel3] = useState("");
    const [artist, setArtist] = useState(null);
    const [contractor, setContractor] = useState(null);
    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;

    const [showDetails, setShowDetails] = useState(false);

    const [openCancelDialog, setOpenCancelDialog] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false);

    const [openContactDialog, setOpenContactDialog] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const [openCommentDialog, setOpenCommentDialog] = useState(false);
    const [comment, setComment] = useState('');
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [commentsPlaceHolder, setCommentsPlaceHolder] = useState('');

    const [showImages, setShowImages] = useState(false);

    const [error, setError] = useState(null);
    const [isFavorite, setIsFavorite] = useState(false);

    const [openRatingDialog, setOpenRatingDialog] = useState(false);
    const [ratingValue, setRatingValue] = useState(0);
    const [ratingSuccess, setRatingSuccess] = useState(false);


    const handleOpenRatingDialog = () => {
        setOpenRatingDialog(true);
    };

    const handleCloseRatingDialog = () => {
        setOpenRatingDialog(false);
        setRatingValue(0);
        setRatingSuccess(false);
    };

    const handleRatingSubmit = async () => {
        setIsLoading(true);
        setError(null);

        const data = JSON.stringify({
            "auth0_sub_id": userRole === 'contractor' ? artistID : contractorID,
            "existing_user_rate": userRole === 'contractor' ? artist.rating : contractor.rating,
            "user_rate": ratingValue
        });

        try {
            const response = await fetch(
                `${railwayAPIURL}/api/v1/util/patchuserrating`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'authKey': apiAuthKey,
                    },
                    body: data,
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                const errorMessage = errorData.error || 'Failed to submit rating';
                throw new Error(errorMessage);
            }

            const patchResponse = await fetch(`${railwayAPIURL}/api/v1/reservation/patchbookingrate`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'authKey': apiAuthKey,
                },
                body: JSON.stringify({
                    contractor_auth0_sub_id: contractorID,
                    artist_auth0_sub_id: artistID,
                    user_role: userRole
                })
            });

            if (!patchResponse.ok) {
                throw new Error('Failed to update booking comment status');
            }

            setRatingSuccess(true);
        } catch (error) {
            console.error('Error submitting rating:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const handleFavoriteUser = async (favoriteStatus) => {
        if (isLoading) return;

        setIsLoading(true);
        setError(null);

        const data = JSON.stringify({
            "auth0_sub_id": artist?.auth0_sub_id,
            "auth0_sub_id_favorited_use": contractor?.auth0_sub_id,
        });

        try {
            const response = await fetch(
                `${railwayAPIURL}/api/v1/profile/favoriteuser`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authKey': apiAuthKey,
                    },
                    body: data,
                }
            );

            if (!response.ok) {
                throw new Error('Failed to update favorite status');
            }

            setIsFavorite(favoriteStatus);
        } catch (error) {
            console.error('Error updating favorite status:', error);
            setError(error.message);
            setIsFavorite(!favoriteStatus);
        } finally {
            setIsLoading(false);
        }
    };

    const debouncedToggleFavorite = debounce(async () => {
        if (isLoading) return;

        const newStatus = !isFavorite;

        setIsFavorite(newStatus);

        await handleFavoriteUser(newStatus);
    }, 300);

    const renderFavoriteButton = () => {
        if (!artist || !contractor) return <></>;
        return (
            <span>
                <IconButton
                    onClick={debouncedToggleFavorite}
                    disabled={isLoading}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        position: 'relative',
                    }}
                >
                    {isLoading ? (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: '#614ED9',
                                position: 'absolute',
                                ml: 6
                            }}
                        />
                    ) : isFavorite ? (
                        <FavoriteOutlinedIcon
                            fontSize="large"
                            sx={{
                                color: '#614ED9',
                                ml: 1,
                                mr: 1,
                            }}
                        />
                    ) : (
                        <FavoriteBorderOutlinedIcon
                            fontSize="large"
                            sx={{
                                color: '#614ED9',
                                ml: 1,
                                mr: 1,
                            }}
                        />
                    )}
                </IconButton>
            </span>
        );
    };

    const whatsappLink = userRole === "contractor"
        ? (artist?.mobile_phone
            ? `https://wa.me/${artist.mobile_phone.replace(/\D/g, '')}?text=Olá! Vi seu perfil no Conexxa e gostaria de conversar sobre a reserva.`
            : '#')
        : userRole === "artist"
            ? (contractor?.mobile_phone
                ? `https://wa.me/${contractor.mobile_phone.replace(/\D/g, '')}?text=Olá! Vi que você fez uma reserva conosco no Conexxa e por isso gostariamos de conversar.`
                : '#')
            : '#';

    const handleOpenContactDialog = () => {
        setOpenContactDialog(true);
    };

    const handleCloseContactDialog = () => {
        setOpenContactDialog(false);
    };

    const handleOpenCancelDialog = () => {
        setOpenCancelDialog(true);
    };

    const handleCloseCancelDialog = () => {
        setOpenCancelDialog(false);
    };


    useEffect(() => {
        if (artist?.artist_name) {
            const newCommentsPlaceHolder = `Escreva seu comentário sobre ${artist.artist_name}`;
            setCommentsPlaceHolder(newCommentsPlaceHolder);
        }
    }, [artist?.artist_name]);

    const handleOpenCommentDialog = () => {
        setOpenCommentDialog(true);
    };

    const handleCloseCommentDialog = () => {
        setOpenCommentDialog(false);
        setComment('');
        setCommentSuccess(false);
    };

    const handleCommentSubmit = async () => {
        if (comment.trim()) {
            setIsLoading(true);
            try {
                const response = await fetch(`${railwayAPIURL}/api/v1/profile/createcomment`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authKey': apiAuthKey,
                    },
                    body: JSON.stringify({
                        auth0_sub_id: artistID,
                        auth0_sub_id_who_commented: contractorID,
                        nickname_who_commented: contractor.nickname,
                        comment: comment,
                        created_at: new Date().toISOString()
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to add comment');
                }

                const patchResponse = await fetch(`${railwayAPIURL}/api/v1/reservation/patchbookingcomment`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'authKey': apiAuthKey,
                    },
                    body: JSON.stringify({
                        contractor_auth0_sub_id: contractorID,
                        artist_auth0_sub_id: artistID,
                        user_role: userRole
                    })
                });

                if (!patchResponse.ok) {
                    throw new Error('Failed to update booking comment status');
                }

                setCommentSuccess(true);
            } catch (error) {
                console.error('Error adding comment or updating booking:', error);
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };


    useEffect(() => {
        const fetchUserArtist = async () => {
            if (!apiAuthKey || !railwayAPIURL) {
                return;
            }

            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/profile/getuser?auth0_sub_id=${artistID}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authKey: apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch user artist");
                }
                const result = await response.json();
                setArtist(result.user);
            } catch (error) {
                console.error("Error fetching user artist:", error);
            }
        };

        fetchUserArtist();
    }, [artistID, apiAuthKey, railwayAPIURL]);

    useEffect(() => {
        const fetchUserContractor = async () => {
            if (!contractorID || !railwayAPIURL || !apiAuthKey) return;

            try {
                const response = await fetch(`${railwayAPIURL}/api/v1/profile/getuser?auth0_sub_id=${contractorID}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authKey': apiAuthKey,
                    },
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    const errorMessage = errorData?.error || response.statusText;
                    throw new Error(`Failed to fetch user contractor: ${errorMessage}`);
                }

                const result = await response.json();
                const contractor = result.user;
                setContractor(contractor);

            } catch (error) {
                console.error('Error fetching user contractor:', error);
            }
        };

        fetchUserContractor();

    }, [railwayAPIURL, apiAuthKey, contractorID]);



    useEffect(() => {
        if (artist && artist.artist_type) {

            const newArtistTypeLabel2 = `${artist.artist_type.includes("band")
                ? "a banda"
                : artist.artist_type.includes("musician")
                    ? "o músico "
                    : artist.artist_type.includes("dj") && "o dj "
                }`;
            const newArtistTypeLabel3 = `${artist.artist_type.includes("band")
                ? "A banda"
                : artist.artist_type.includes("musician")
                    ? "O músico "
                    : artist.artist_type.includes("dj") && "O dj "
                }`;
            setArtistTypeLabel2(newArtistTypeLabel2);
            setArtistTypeLabel3(newArtistTypeLabel3);
        }
    }, [artist]);

    const formatDateToBrazilian = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${day}/${month}/${year}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${year}-${month}-${day}`;
    };

    const handleCancelBooking = async () => {
        if (!apiAuthKey || !railwayAPIURL) return;

        setIsLoading(true);
        const startTime = Date.now();

        try {
            const response = await fetch(`${railwayAPIURL}/api/v1/reservation/cancelbooking`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'authKey': apiAuthKey,
                },
                body: JSON.stringify({
                    contractor_auth0_sub_id: contractorID,
                    artist_auth0_sub_id: artistID,
                    booking_date: formatDate(date),
                    booking_period: period
                })
            });


            if (!response.ok) {
                throw new Error('Failed to cancel booking');
            }

            await createNotification(
                contractorID,
                `Reserva ${truncateBookingId(bookingID)} cancelada`,
                `A reserva de no. '${bookingID}' com ${artistTypeLabel2} '${artist?.artist_name}' para o dia '${formatDateToBrazilian(date)}' no período da '${period}' foi CANCELADA.`
            );

            await createNotification(
                artistID,
                `Reserva '${truncateBookingId(bookingID)}' cancelada`,
                `A reserva de no. '${bookingID}' com o contratante '${contractor?.name}' do estabelecimento '${contractor?.establishment_name}' para o dia '${formatDateToBrazilian(date)}' no período da '${period}' foi CANCELADA.`
            );

            const elapsedTime = Date.now() - startTime;
            const remainingTime = Math.max(0, 2500 - elapsedTime);

            if (remainingTime > 0) {
                await new Promise(resolve => setTimeout(resolve, remainingTime));
            }

            setCancelSuccess(true);
        } catch (error) {
            console.error('Error canceling booking:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const truncateBookingId = (bookingId) => {
        if (!bookingId) return '';
        return `...${bookingId.slice(-4)}`;
    };

    const createNotification = async (auth0_sub_id, notification_title, notification_text) => {
        if (!apiAuthKey || !railwayAPIURL) return;

        try {
            const notificationResponse = await fetch(`${railwayAPIURL}/api/v1/profile/createnotification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authKey': apiAuthKey,
                },
                body: JSON.stringify({
                    auth0_sub_id: auth0_sub_id,
                    notification_title: notification_title,
                    notification_text: notification_text
                })
            });

            if (!notificationResponse.ok) {
                throw new Error('Failed to send notification');
            }
        } catch (error) {
            console.error('Error creating notification:', error);
        }
    };

    return (
        <Card
            elevation={2}
            sx={{
                position: 'relative',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '2px',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0))',
                    borderTopLeftRadius: '1px',
                    borderTopRightRadius: '1px',
                },
                mb: 5
            }}
        >
            <CardContent>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: {
                                xs: isMobile ? '75%' : '100%',
                                sm: isMobile ? '75%' : '90%'
                            },
                            maxWidth: 800,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                            maxHeight: '90vh',
                            overflow: 'auto',
                        }}
                    >
                        <IconButton
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'grey.500',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="h2"
                            sx={{
                                mb: 3,
                                textAlign: {
                                    xs: isMobile ? 'center' : 'left',
                                    sm: isMobile ? 'center' : 'left',
                                }
                            }}
                        >
                            Imagens do Estabelecimento
                        </Typography>
                        <Box>
                            <Button
                                onClick={() => setShowImages(!showImages)}
                                startIcon={showImages ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                                sx={{
                                    textTransform: 'none',
                                    color: '#614ed9',
                                    p: 0,
                                    mb: 1,
                                    '&:hover': {
                                        background: 'none',
                                        textDecoration: 'underline'
                                    }
                                }}
                            >
                                {showImages ? 'Ocultar imagens do estabelecimento' : 'Mostrar imagens do estabelecimento'}
                            </Button>
                            <Collapse in={showImages}>
                                <QuiltedImageList imageUrls={contractor?.establishment_pictures} />
                            </Collapse>
                        </Box>
                    </Box>
                </Modal>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: isMobile ? 'center' : 'space-between',
                    }}
                >
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h5" gutterBottom sx={{ marginRight: 2 }}>
                                    Reserva
                                </Typography>
                                {!isMobile && (
                                    <>
                                        {bookingStatus === "completo" ? (
                                            <Chip label="Realizada" sx={{ backgroundColor: '#A89CF0', color: '#fff' }} />
                                        ) : bookingStatus === "ativo" ? (
                                            <Chip label="Agendada" sx={{ backgroundColor: '#68EB1A', color: '#fff' }} />
                                        ) : bookingStatus === "cancelado" ? (
                                            <Chip label="Cancelada" sx={{ backgroundColor: '#FFD01C', color: '#fff' }} />
                                        ) : (
                                            <Chip label={bookingStatus} color="primary" />
                                        )}
                                    </>
                                )}
                            </Box>
                        </Grid>
                        {isMobile && (
                            <Grid item sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {bookingStatus === "completo" ? (
                                        <Chip label="Realizada" sx={{ backgroundColor: '#A89CF0', color: '#fff' }} />
                                    ) : bookingStatus === "ativo" ? (
                                        <Chip label="Agendada" sx={{ backgroundColor: '#68EB1A', color: '#fff' }} />
                                    ) : bookingStatus === "cancelado" ? (
                                        <Chip label="Cancelada" sx={{ backgroundColor: '#FFD01C', color: '#fff' }} />
                                    ) : (
                                        <Chip label={bookingStatus} color="primary" />
                                    )}
                                </Box>
                            </Grid>
                        )}
                    </Grid>

                    <>
                        {bookingStatus === "ativo" && (
                            <Grid item xs={12} sm={6}>
                                {!isMobile && (
                                    <BookingActions
                                        isMobile={isMobile}
                                        onCancelClick={handleOpenCancelDialog}
                                        onContactClick={handleOpenContactDialog}
                                    />
                                )}
                            </Grid>
                        )}
                    </>

                    <>
                        {!isMobile && bookingStatus === "completo" ? (
                            <>
                                <Button
                                    component="label"
                                    variant="text"
                                    onClick={handleOpenCommentDialog}
                                    startIcon={
                                        <CommentOutlinedIcon
                                            sx={{
                                                fontSize: '1rem',
                                            }}
                                        />
                                    }
                                    sx={{
                                        textTransform: 'none',
                                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#614ed9'),
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Fazer comentário
                                </Button>
                                <Box sx={{ mx: 5 }} />
                                <Button
                                    component="label"
                                    variant="text"
                                    onClick={handleOpenRatingDialog}
                                    startIcon={
                                        <GradeOutlinedIcon
                                            sx={{
                                                fontSize: '1rem',
                                                color: '#614ed9',
                                            }}
                                        />
                                    }
                                    sx={{
                                        textTransform: 'none',
                                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#614ed9'),
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Avaliar
                                </Button>
                            </>

                        ) : null}
                    </>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                            <Typography sx={{ textAlign: 'left' }}>No. da reserva:</Typography>
                            <Box
                                component="span"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? theme.palette.grey[700]
                                            : theme.palette.grey[100],
                                    borderRadius: '4px',
                                    padding: '2px 4px',
                                    marginLeft: '4px',
                                    textAlign: 'left',
                                }}
                            >
                                <Typography variant="body2" sx={{ textAlign: 'left' }}>{bookingID}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{ height: '1px', backgroundColor: 'rgba(0, 0, 0, 0.12)', mt: 1, mb: 2 }} />

                <Typography variant="subtitle1" align="left">Contratante:</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" align="left">{contractor?.name}</Typography>
                    {userRole === 'artist' && (
                        <Tooltip
                            title={
                                <Box>
                                    <Typography>
                                        {isLoading
                                            ? "Atualizando..."
                                            : error
                                                ? "Erro ao atualizar. Tente novamente."
                                                : isFavorite
                                                    ? "Clique para remover dos seus favoritos"
                                                    : "Clique para adicionar este contratante aos seus favoritos"
                                        }
                                    </Typography>
                                </Box>
                            }
                            arrow
                            placement="bottom"
                        >
                            {renderFavoriteButton()}
                        </Tooltip>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Rating sx={{ mr: 1 }} name="read-only" value={Math.floor(contractor?.rating)} readOnly />
                    <Typography>({contractor?.rating.toFixed(1)})</Typography>
                </Box>


                <Typography variant="subtitle1" align="left">Artista:</Typography>
                <Typography variant="h6" align="left">{artist?.artist_name}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'flex-start' }}>
                    <Rating sx={{ mr: 1 }} name="read-only" value={Math.floor(artist?.rating)} readOnly />
                    <Typography>({artist?.rating.toFixed(1)})</Typography>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    mt: 1
                }}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            height: '56px',
                            backgroundImage: `url(/static/images/noun-zig-zag-924531.png)`,
                            backgroundPosition: 'left center',
                            backgroundRepeat: 'repeat-x',
                            backgroundSize: 'auto 16px',
                            mr: 1,
                        }}
                    />

                    <Button
                        onClick={() => setShowDetails(!showDetails)}
                        sx={{
                            textTransform: 'none',
                            color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#614ed9',
                            whiteSpace: 'nowrap',
                            flexShrink: 0,
                        }}
                    >
                        {showDetails ? 'Ocultar detalhes da reserva' : 'Mostrar detalhes da reserva'}
                    </Button>

                    <Box
                        sx={{
                            flexGrow: 1,
                            height: '56px',
                            backgroundImage: `url(/static/images/noun-zig-zag-924531.png)`,
                            backgroundPosition: 'left center',
                            backgroundRepeat: 'repeat-x',
                            backgroundSize: 'auto 16px',
                            ml: 1,
                        }}
                    />
                </Box>

                <Collapse in={showDetails}>
                    <Typography align="left">{artistTypeLabel3}{" "}
                        <Box
                            component="span"
                            sx={{
                                display: 'inline-block',
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.grey[700]
                                        : theme.palette.grey[100],
                                borderRadius: 1,
                                px: 1,
                                py: 0.5,
                                mb: 1,
                            }}
                        >
                            {artist?.artist_name}
                        </Box>

                    </Typography>

                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        Com{" "}
                        <Box
                            component="span"
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                borderRadius: 1,
                                px: 1,
                                py: 0.5,
                                mx: 1,
                            }}
                        >
                            {bookingMembersNumber}
                        </Box>
                        <Groups2OutlinedIcon sx={{ mr: 1 }} />
                        integrantes
                    </Typography>

                    {!isMobile ? (
                        <>
                            <Grid container spacing={2} sx={{ mt: 0.5 }}>
                                <Grid item xs={6}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            height: '100%',
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? theme.palette.grey[700]
                                                    : theme.palette.grey[100],
                                            borderRadius: 1,
                                            px: 1,
                                            py: 0.5,
                                            mr: 6,
                                            textAlign: 'left',
                                            position: 'relative',
                                        }}
                                    >
                                        <Typography align="left" display="block">
                                            VEM DE
                                        </Typography>
                                        {artist && (
                                            <Typography component="span" variant="body2" sx={{ fontWeight: 'bold' }}>
                                                {artist?.zone}, {artist?.city}, {artist?.province}, {artist?.country}
                                            </Typography>
                                        )}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '-56px',
                                                width: '56px',
                                                height: '2px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            height: '100%',
                                            backgroundColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? theme.palette.grey[700]
                                                    : theme.palette.grey[100],
                                            borderRadius: 1,
                                            px: 1,
                                            py: 0.5,
                                            ml: 6,
                                            textAlign: 'left',
                                            position: 'relative',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography align="left" display="block">
                                                TOCAR EM
                                            </Typography>
                                            {userRole === 'artist' && (
                                                <IconButton
                                                    size="small"
                                                    onClick={handleOpenModal}
                                                    sx={{
                                                        color: '#614ed9',
                                                        p: 0.5
                                                    }}
                                                >
                                                    <PhotoLibraryIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                        {contractor && (
                                            <Typography component="span" variant="body2" sx={{ fontWeight: 'bold' }}>
                                                {`${contractor?.establishment_name}, ${contractor?.address}, ${contractor?.address_number}, ${contractor?.zone}, ${contractor?.zipcode}, ${contractor?.city}, ${contractor?.province}, ${contractor?.country}`}
                                            </Typography>
                                        )}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '-56px',
                                                width: '56px',
                                                height: '2px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                            }}
                                        />

                                    </Box>

                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid container spacing={2} sx={{ mt: 0.5 }}>
                                <Grid item xs={1} sx={{ position: 'relative' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                                        <AdjustIcon sx={{ mb: 0 }} />
                                        <Box sx={{ flexGrow: 1, width: 2, backgroundColor: 'grey.300' }} />
                                        <AdjustIcon sx={{ mt: 0 }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography align="left">VEM DE:</Typography>
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                            borderRadius: 1,
                                            px: 1,
                                            py: 0.5,
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                textAlign: 'left',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'normal',
                                                wordWrap: 'break-word',
                                                flexGrow: 1,
                                                mr: 1,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {artist ? `${artist?.zone}, ${artist?.city}, ${artist?.province}, ${artist?.country}` : ''}
                                        </Typography>
                                    </Box>
                                    <Typography align="left">TOCAR EM:</Typography>

                                    <Typography align="left" sx={{ fontWeight: 'bold' }}>
                                        <Box
                                            component="span"
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                                borderRadius: 1,
                                                px: 1,
                                                py: 0.5,
                                                maxWidth: '100%',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    textAlign: 'left',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    flexGrow: 1,
                                                    mr: 1,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {contractor ? `${contractor.establishment_name}, ${contractor.address}, ${contractor.address_number}, ${contractor.zone}, ${contractor.zipcode}, ${contractor.city}, ${contractor.province}, ${contractor.country}` : ''}
                                            </Typography>

                                            {userRole === 'artist' && (
                                                <IconButton
                                                    size="small"
                                                    onClick={handleOpenModal}
                                                    sx={{
                                                        color: '#614ed9',
                                                        p: 0.5,
                                                        flexShrink: 0,
                                                    }}
                                                >
                                                    <PhotoLibraryIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        {date !== undefined && date !== null && (
                            <Typography sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                                No dia <CalendarMonthOutlinedIcon sx={{ mx: 1 }} />
                            </Typography>
                        )}
                        {date ? (
                            <Box
                                component="span"
                                sx={{
                                    display: 'inline-block',
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                    borderRadius: 1,
                                    px: 1,
                                    py: 0.5,
                                }}
                            >
                                <Typography>
                                    {formatDateToBrazilian(date)}
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <CalendarMonthOutlinedIcon sx={{ mx: 1 }} />
                                <Alert severity="warning" sx={{ flexGrow: 1 }}>
                                    A data de reserva ainda não o foi definida, por favor escolha um dia, mês e ano.
                                </Alert>
                            </Box>
                        )}
                    </Box>

                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        {period !== undefined && period !== null && (
                            <Typography sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                                De
                            </Typography>
                        )}
                        {(() => {
                            let startTime, endTime;
                            switch (period) {
                                case 'Manhã':
                                    startTime = '06:00';
                                    endTime = '11:59';
                                    break;
                                case 'Tarde':
                                    startTime = '12:00';
                                    endTime = '17:59';
                                    break;
                                case 'Noite':
                                    startTime = '18:00';
                                    endTime = '23:59';
                                    break;
                                default:
                                    if (period === '') {
                                        return (
                                            <>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <AccessTimeOutlinedIcon sx={{ mx: 1 }} />
                                                    <Alert severity="warning" sx={{ flexGrow: 1 }}>
                                                        O período da reserva ainda não foi definido, por favor escolha um período para continuar a sua reserva.
                                                    </Alert>
                                                </Box>
                                            </>
                                        );
                                    }
                                    return <Typography component="span">{period}</Typography>;
                            }
                            return (
                                <>
                                    <AccessTimeOutlinedIcon sx={{ mx: 1 }} />
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'inline-block',
                                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                            borderRadius: 1,
                                            px: 1,
                                            py: 0.5,
                                            mr: 1,
                                        }}
                                    >
                                        <Typography component="span">{startTime}</Typography>
                                    </Box>
                                    <Typography component="span" sx={{ mx: 1 }}>
                                        às
                                    </Typography>
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'inline-block',
                                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                            borderRadius: 1,
                                            px: 1,
                                            py: 0.5,
                                        }}
                                    >
                                        <Typography component="span">{endTime}</Typography>
                                    </Box>
                                </>
                            );
                        })()}
                    </Box>

                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Typography align="left">Por {" "}
                            <Box
                                component="span"
                                sx={{
                                    display: 'inline-block',
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? theme.palette.grey[700]
                                            : theme.palette.grey[100],
                                    borderRadius: 1,
                                    px: 1,
                                    py: 0.5,
                                    mb: 1,
                                }}
                            >
                                <strong>R$</strong> {bookingPrice}
                            </Box>

                        </Typography>
                    </Box>
                    <>
                        {bookingStatus === "ativo" && (
                            <Grid item xs={12} sm={6}>
                                {isMobile && (
                                    <BookingActions
                                        isMobile={isMobile}
                                        onCancelClick={handleOpenCancelDialog}
                                        onContactClick={handleOpenContactDialog}
                                    />
                                )}
                            </Grid>
                        )}
                    </>
                    <>
                        {isMobile && bookingStatus === "completo" && (
                            <Box
                                sx={{
                                    mt: 6,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    component="label"
                                    variant="text"
                                    onClick={handleOpenCommentDialog}
                                    startIcon={
                                        <CommentOutlinedIcon
                                            sx={{
                                                fontSize: '1rem',
                                            }}
                                        />
                                    }
                                    sx={{
                                        textTransform: 'none',
                                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#614ed9'),
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Fazer comentário
                                </Button>
                                <Button
                                    component="label"
                                    variant="text"
                                    onClick={handleOpenRatingDialog}
                                    startIcon={
                                        <GradeOutlinedIcon
                                            sx={{
                                                fontSize: '1rem',
                                                color: '#614ed9'
                                            }}
                                        />
                                    }
                                    sx={{
                                        textTransform: 'none',
                                        color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#614ed9'),
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    Avaliar
                                </Button>
                            </Box>
                        )}
                    </>
                </Collapse>
            </CardContent>
            <Dialog
                open={openCancelDialog}
                onClose={handleCloseCancelDialog}
                aria-labelledby="cancel-booking-dialog"
                maxWidth="sm"
                fullWidth
            >
                {!cancelSuccess ? (
                    <>
                        <DialogTitle sx={{ pb: 1 }}>Confirmar Cancelamento</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" sx={{ mb: 2, textAlign: 'justify' }}>
                                Deseja cancelar a reserva de No. {" "}
                                <Box
                                    component="span"
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? theme.palette.grey[700]
                                                : theme.palette.grey[100],
                                        borderRadius: '4px',
                                        padding: '2px 4px',
                                        marginLeft: '4px',
                                        textAlign: 'left',
                                    }}
                                >
                                    {bookingID}
                                </Box>
                                {" "} feita pelo contratante
                                <Box
                                    component="span"
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? theme.palette.grey[700]
                                                : theme.palette.grey[100],
                                        borderRadius: '4px',
                                        padding: '2px 4px',
                                        marginLeft: '4px',
                                        textAlign: 'left',
                                    }}
                                >{contractor?.name}</Box>
                                {" "} para {artistTypeLabel2} {" "}
                                <Box
                                    component="span"
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? theme.palette.grey[700]
                                                : theme.palette.grey[100],
                                        borderRadius: '4px',
                                        padding: '2px 4px',
                                        marginLeft: '4px',
                                        textAlign: 'left',
                                    }}
                                >
                                    {artist?.artist_name}
                                </Box>
                                {" "} agendada para a data de {" "}
                                <Box
                                    component="span"
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? theme.palette.grey[700]
                                                : theme.palette.grey[100],
                                        borderRadius: '4px',
                                        padding: '2px 4px',
                                        marginLeft: '4px',
                                        textAlign: 'left',
                                    }}
                                >
                                    {formatDateToBrazilian(date)}
                                </Box>
                                {" "} no horário da {" "}
                                <Box
                                    component="span"
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? theme.palette.grey[700]
                                                : theme.palette.grey[100],
                                        borderRadius: '4px',
                                        padding: '2px 4px',
                                        marginLeft: '4px',
                                        textAlign: 'left',
                                    }}
                                >
                                    {period}
                                </Box>
                                ?
                            </Typography>
                            <Alert severity="info" sx={{ mb: 2 }}>
                                {userRole === "contractor" ? (
                                    <>
                                        {artistTypeLabel3}{' '}
                                        <span style={{ color: '#614ed9', textDecoration: 'underline' }}>
                                            {artist?.artist_name}
                                        </span>
                                        {' '}será notificado que a sua reserva foi{' '}
                                        <span style={{ color: '#614ed9', textDecoration: 'underline' }}>cancelada.</span>
                                    </>
                                ) : (
                                    <>
                                        O{' '}
                                        <span style={{ color: '#614ed9', textDecoration: 'underline' }}>
                                            contratante responsável
                                        </span>
                                        {' '}será notificado que a sua reserva foi {' '}
                                        <span style={{ color: '#614ed9', textDecoration: 'underline' }}>cancelada.</span>
                                    </>
                                )}
                            </Alert>
                        </DialogContent>
                        <DialogActions sx={{ px: 3, pb: 2 }}>
                            <Button
                                onClick={handleCloseCancelDialog}
                                sx={{
                                    color: '#614ed9',
                                    borderColor: '#614ed9',
                                    '&:hover': {
                                        borderColor: '#4e3bb3',
                                        color: '#4e3bb3',
                                    },
                                }}
                            >
                                Voltar
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleCancelBooking}
                                disabled={isLoading}
                                sx={{
                                    backgroundColor: '#614ed9',
                                    color: '#fff',
                                    minWidth: '120px',
                                    '&:hover': {
                                        backgroundColor: '#4e3bb3',
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: '#f5f5f5',
                                        color: 'rgba(0, 0, 0, 0.26)'
                                    }
                                }}
                            >
                                {isLoading ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <CircularProgress size={20} color="inherit" sx={{ color: '#614ed9' }} />
                                        <span style={{ color: '#614ed9' }}>Cancelando...</span>
                                    </Box>
                                ) : (
                                    'Sim, Cancelar'
                                )}
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogTitle sx={{ pb: 1 }}>Cancelamento Confirmado</DialogTitle>
                        <DialogContent>
                            <Typography variant="body1" sx={{ mb: 2, textAlign: 'left' }}>
                                Sua reserva foi cancelada com sucesso.
                            </Typography>
                        </DialogContent>
                        <DialogActions sx={{ px: 3, pb: 2 }}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleCloseCancelDialog();
                                    setCancelSuccess(false);
                                    window.location.reload();
                                }}
                                sx={{
                                    backgroundColor: '#614ed9',
                                    color: '#fff',
                                    '&:hover': {
                                        backgroundColor: '#4e3bb3',
                                    },
                                }}
                            >
                                Fechar
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            <Dialog
                open={openCommentDialog}
                onClose={handleCloseCommentDialog}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Deixe o seu comentário</DialogTitle>
                <DialogContent>
                    {commentSuccess ? (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            Seu comentário foi adicionado com sucesso!
                        </Alert>
                    ) : hasCommented ? (
                        <Alert severity="info" sx={{ mt: 2 }}>
                            Você já deixou seu comentário sobre esta reserva. Obrigado pela sua opinião.
                        </Alert>
                    ) : (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%', mt: 2 }}>
                                <Avatar src={userPicture} sx={{ width: 32, height: 32, mr: 2 }} />
                                <TextField
                                    fullWidth
                                    placeholder={commentsPlaceHolder}
                                    variant="outlined"
                                    multiline
                                    minRows={3}
                                    sx={{ mb: 1 }}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 2 }}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        handleCommentSubmit();
                                    }}
                                    disabled={isLoading}
                                    sx={{
                                        textTransform: 'capitalize',
                                        backgroundColor: '#614ED9'
                                    }}
                                >
                                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Comentar'}
                                </Button>
                            </Box>
                            <Typography
                                variant="caption"
                                sx={{
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'right',
                                    mt: 1,
                                    mr: 0
                                }}
                            >
                                Ver {" "}
                                <Link
                                    href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#614ed9'
                                    }}
                                >
                                    Política de Uso
                                </Link>
                            </Typography>
                        </>
                    )}
                </DialogContent>
                {(commentSuccess || hasCommented) && (
                    <DialogActions>
                        <Button onClick={() => {
                            handleCloseCommentDialog();
                            if (!hasCommented) {
                                window.location.reload();
                            }
                        }}
                            sx={{
                                color: '#614ed9',
                                borderColor: '#614ed9',
                                '&:hover': {
                                    borderColor: '#4e3bb3',
                                    color: '#4e3bb3',
                                },
                            }}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                )}
            </Dialog>


            <Dialog
                open={openContactDialog}
                onClose={handleCloseContactDialog}
                maxWidth="xs"
                fullWidth={isMobile}
                PaperProps={{
                    sx: {
                        width: isMobile ? '100%' : '400px',
                        maxWidth: '100%'
                    }
                }}
            >
                <DialogTitle sx={{
                    textAlign: isMobile ? 'center' : 'left',
                    pl: isMobile ? 2 : 3
                }}>
                    Opções de contato disponíveis
                </DialogTitle>
                <DialogContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 3,
                    py: 4
                }}>
                    <Alert severity="info" sx={{ mt: 1, width: '100%' }}>
                        Entre em contato com {userRole === 'contractor' ? <span style={{ color: '#614ed9', textDecoration: 'underline' }}>{artist?.artist_name}</span> : userRole === 'artist' ? <span style={{ color: '#614ed9', textDecoration: 'underline' }}>{contractor?.name}</span> : 'usuário'} usando as opções disponíveis abaixo.
                    </Alert>

                    <Button
                        variant="contained"
                        startIcon={<WhatsAppIcon />}
                        href={whatsappLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="large"
                        sx={{
                            backgroundColor: '#25D366',
                            '&:hover': { backgroundColor: '#128C7E' },
                            py: 1.5,
                            px: 4
                        }}
                    >
                        Abrir WhatsApp
                    </Button>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1
                    }}>
                        <Typography variant="body2" color="text.secondary">
                            ou se preferir
                        </Typography>
                        <Typography variant="subtitle2" sx={{ fontWeight: 'medium' }}>
                            {userRole === "contractor" ? artist?.mobile_phone : userRole === "artist" ? contractor?.mobile_phone : ""}
                        </Typography>
                    </Box>
                    <Typography
                        variant="caption"
                        sx={{
                            display: 'block',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                            mt: 1,
                            mr: 0
                        }}
                    >
                        Ver {" "}
                        <Link
                            href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                fontWeight: 'bold',
                                color: '#614ed9'
                            }}
                        >
                            Política de Uso
                        </Link>
                    </Typography>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openRatingDialog}
                onClose={handleCloseRatingDialog}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Deixe a sua avaliação</DialogTitle>
                <DialogContent>
                    {ratingSuccess ? (
                        <Alert severity="success" sx={{ mt: 2 }}>
                            Avaliação feita com sucesso!
                        </Alert>
                    ) : hasRated ? (
                        <>
                            <Alert severity="info" sx={{ mt: 2 }}>
                                Sua avaliação para esta reserva já foi registrada. Obrigado!
                            </Alert>
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', mt: 2 }}>
                            <Rating
                                name="rating"
                                value={ratingValue}
                                onChange={(event, newValue) => {
                                    setRatingValue(newValue);
                                }}
                                size="large"
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 2 }}>
                                <Button
                                    variant="contained"
                                    onClick={handleRatingSubmit}
                                    disabled={isLoading}
                                    sx={{
                                        textTransform: 'capitalize',
                                        backgroundColor: '#614ED9'
                                    }}
                                >
                                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Comentar'}
                                </Button>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                {(ratingSuccess || hasRated) && (
                    <DialogActions>
                        <Button onClick={() => {
                            handleCloseRatingDialog();
                            if (!hasRated) {
                                window.location.reload();
                            }
                        }}
                            sx={{
                                color: '#614ed9',
                                borderColor: '#614ed9',
                                '&:hover': {
                                    borderColor: '#4e3bb3',
                                    color: '#4e3bb3',
                                },
                            }}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                )}
            </Dialog>

        </Card>
    );
};

export default BookingScheduledCard;
