import React from 'react';
import { Box, Button, Typography, Link } from '@mui/material';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';

const BookingActions = ({ isMobile, onCancelClick, onContactClick }) => {
    const commonButtonStyles = {
        height: '36px',
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
    };

    const buttonStyles = {
        ...commonButtonStyles,
        backgroundColor: '#614ed9',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#4e3bb3',
        },
    };

    const contactButtonStyles = {
        ...commonButtonStyles,
        borderColor: '#614ed9',
        color: '#614ed9',
        '&:hover': {
            borderColor: '#4e3bb3',
            backgroundColor: 'transparent',
        },
    };

    const containerStyles = isMobile ? {
        mt: 6,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    } : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        height: '100%',
        width: '100%',
    };

    const buttonContainerStyles = isMobile ? {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    } : {
        minWidth: '220px',
        width: 'fit-content'
    };

    const buttonsWrapperStyles = {
        display: 'flex',
        gap: 1,
        mb: 1,
        mt: 1,
        width: isMobile ? '100%' : 'auto',
        flexDirection: isMobile ? 'column' : 'row'
    };

    const cancelButtonWrapperStyles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: isMobile ? '100%' : 'auto'
    };

    return (
        <Box sx={containerStyles}>
            <Box sx={buttonContainerStyles}>
                <Box sx={buttonsWrapperStyles}>
                    <Button
                        variant="outlined"
                        onClick={onContactClick}
                        sx={{
                            ...contactButtonStyles,
                            width: isMobile ? '100%' : 'auto'
                        }}
                        startIcon={<MarkUnreadChatAltOutlinedIcon />}
                    >
                        Entrar em contato
                    </Button>
                    <Box sx={cancelButtonWrapperStyles}>
                        <Button
                            variant="contained"
                            onClick={onCancelClick}
                            sx={{
                                ...buttonStyles,
                                width: isMobile ? '100%' : 'auto'
                            }}
                        >
                            Cancelar Reserva
                        </Button>
                        <Box sx={{ 
                            width: '100%', 
                            display: 'flex', 
                            justifyContent: isMobile ? 'center' : 'flex-start' 
                        }}>
                            <Typography
                                variant="caption"
                                sx={{
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    textAlign: isMobile ? 'center' : 'left',
                                    mt: 1,
                                    mr: 0
                                }}
                            >
                                Ver {" "}
                                <Link
                                    href="https://conexxamusic.github.io/Termos-e-Condi-es/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        fontWeight: 'bold',
                                        color: '#614ed9'
                                    }}
                                >
                                    Política de Cancelamento
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BookingActions;