import CardsList from "../CardsList";
import { useParams } from 'react-router-dom';
import HeroFooter from "../HeroFooter";
import { useEffect } from "react";

const Favorites = ({
    userRole
}) => {
    const { id: userID } = useParams();
    const actionArtistList = 'favorites';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {userID && (
                <CardsList
                    actionArtistList={actionArtistList}
                    userID={userID}
                    userRole={userRole}
                />
            )}
            <HeroFooter />
        </>
    );
}

export default Favorites;