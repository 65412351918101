import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

class AnaliticsService {

    static analytics;

    static firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MESSUREMENT_ID
    };

    static EventTypes = {
        // Quando o usuarion faz click no botao de buscar no buscador principal no topo da home
        DidClickSearch: "Search",
        // Quando o usuario faz click em ver mais em algum artista do resultado da busca
        DidClickSeeMore: "SeeMore",
        // Quando o usuario faz click em reservar na pagina de algum artista
        DidClickBook: "Book",
        // Quando o usuario faz click em login ou signup na parte equerda da nav bar
        DidClickSignUp: "SignUp_Login",
        // Quando o usuario clica em registar como banda
        DidClickBandRegister: "BandRegister",
        // Quando o usuario clica em registar como artista
        DidClickContractorRegister: "ContractorRegister",
    };

    static initFirebaseService() {
        if (this.analytics != null || process.env.REACT_APP_ENV === "DEV" || this.firebaseConfig.apiKey == null) return;
        const app = initializeApp(this.firebaseConfig);
        this.analytics = getAnalytics(app);
    }

    static sendEvent(event) {
        if (!this.analytics) {
            console.error('Analytics instance is not initialized');
            this.initFirebaseService();
            if (!this.analytics) return;
        }
        logEvent(this.analytics, event);
    }
}

AnaliticsService.initFirebaseService();

export default AnaliticsService;