import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchResultCard from './widget/SearchResultCard';
import { Box, Typography, useMediaQuery, CircularProgress } from '@mui/material';
import Search from './utils/Search';
import ThereIsNothingHere from './utils/ThereIsNothingHere';
import FilterBar from './utils/FilterBar';

const SearchResults = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [searchParams] = useSearchParams();
    const [artists, setArtists] = useState([]);
    const artistProfileSelected = searchParams.get('artistProfileSelected');
    const artistProfileSelectedLabel = searchParams.get('artistProfileSelected') === 'band' ? 'banda' :
        searchParams.get('artistProfileSelected') === 'musician' ? 'músico' :
            searchParams.get('artistProfileSelected') === 'dj' && 'dj';
    const countryCode = searchParams.get('countryCode');
    const provinceCode = searchParams.get('provinceCode');
    const city = searchParams.get('city');
    const zone = searchParams.get('zone');
    const musicStyle = searchParams.get('musicStyle');
    const period = searchParams.get('period');
    const date = new Date(searchParams.get('date'));
    const formattedDate = date.toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [showSearchBar, setShowSearchBar] = useState(true);
    const [loading, setLoading] = useState(false);


    const handleOpenSearch = () => {
        setShowSearchBar(false);
    };

    useEffect(() => {
        const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
        const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;

        const fetchSearchResults = async () => {
            setLoading(true);
            const url = `${railwayAPIURL}/api/v1/search/getsearchresult?artist_type=${artistProfileSelected}&country_code=${countryCode}&province_code=${provinceCode}&city=${city}&zone=${zone}&music_styles=${musicStyle}&period=${period}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authKey': apiAuthKey,
                },
            });

            if (!response.ok) {
                console.error('Error fetching search results:', response.status);
                setLoading(false);
                return;
            }

            const result = await response.json();
            setArtists(result);
            setLoading(false);
        };
        if (railwayAPIURL && apiAuthKey) {
            fetchSearchResults();
        }
    }, [searchParams, artistProfileSelected, countryCode, provinceCode, city, zone, musicStyle, period]);

    return (
        <div>
            <Box sx={{
                padding: isMobile ? '20px 5px' : '20px 10px',
                marginBottom: '40px',
                mx: 4,
                mt: showSearchBar ? 2 : 6
            }}>

                {showSearchBar ? (
                    <>
                        <FilterBar
                            artistProfileSelectedLabel={artistProfileSelectedLabel}
                            countryCode={countryCode}
                            provinceCode={provinceCode}
                            city={city}
                            zone={zone}
                            musicStyle={musicStyle}
                            period={period}
                            formattedDate={formattedDate}
                            handleOpenSearch={handleOpenSearch}
                        />


                    </>
                ) : (
                    <>
                        <Box
                            sx={{
                                mt: 2,
                                mb: 6
                            }}
                        >
                            <Search />
                        </Box>
                    </>
                )}
                <Typography variant='h6'
                    sx={{
                        mt: 1,
                        textAlign: 'left',
                    }}
                >
                    Resultados encontrados
                </Typography>
                <Box sx={{
                    mt: isMobile ? 3 : 8,
                    mb: isMobile ? 8 : 6
                }}>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress sx={{ color: '#4F46E5' }} />
                        </Box>
                    ) : (
                        <>
                            {artists && artists.length > 0 ? (
                                artists.map((artist, index) => (
                                    <SearchResultCard
                                        key={artist.id || index}
                                        artist={artist}
                                        artistProfileSelected={artistProfileSelected}
                                        countryCode={countryCode}
                                        provinceCode={provinceCode}
                                        city={city}
                                        zone={zone}
                                        musicStyle={musicStyle}
                                        period={period}
                                        date={date}

                                    />
                                ))
                            ) : (
                                <ThereIsNothingHere
                                    title="Ops! Não encontramos resultados para sua busca"
                                    subtitle="Experimente ajustar os filtros de busca (região, estilo musical, período, etc.) para refinar os resultados e encontrar o profissional que você procura."
                                />
                            )}
                        </>
                    )}

                </Box>
            </Box>
        </div>
    );
};

export default SearchResults;