import BookingScheduledCard from "../widget/BookingScheduledCard";
import { useParams } from 'react-router-dom';
import { Box, useMediaQuery, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ThereIsNothingHere from "../utils/ThereIsNothingHere";
import HeroFooter from "../HeroFooter";

const BookingScheduled = ({
    userPicture,
    userRole
}) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { id: userID } = useParams();
    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [bookings, setBookings] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        const fetchBooking = async () => {
            if (!apiAuthKey || !railwayAPIURL || !userID || !userRole) {
                setIsLoading(false);
                return;
            }
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/reservation/getbooking${userRole === 'contractor' ? `?contractor_auth0_sub_id=${userID}` : userRole === 'artist' ? `?artist_auth0_sub_id=${userID}` : ''}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'authKey': apiAuthKey,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch user booking list');
                }

                const result = await response.json();

                if (result.data && Array.isArray(result.data)) {
                    const sortedBookings = result.data.sort((a, b) =>
                        new Date(b.booking_date) - new Date(a.booking_date)
                    );
                    setBookings(sortedBookings);
                } 
            } catch (error) {
                console.error('Error fetching user booking list:', error);
            } finally {
                setIsLoading(false);
                setDataFetched(true)
            }
        };

        fetchBooking();
    }, [userID, apiAuthKey, railwayAPIURL, userRole]);
    

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Box sx={{ flexGrow: 1, padding: isMobile ? '20px 5px' : '20px 10px', mt: 8, mx: 3 }}>
                {isLoading && !dataFetched && bookings === null ? (
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 30 }}>
                        <CircularProgress sx={{ color: "#4F46E5" }} />
                        <Typography sx={{ mt: 2 }} variant="h6" color="textSecondary">
                            Carregando suas reservas...
                        </Typography>
                    </Box>
                ) : !isLoading && dataFetched && bookings === null ? (
                    <ThereIsNothingHere
                        title="Ops!Você não tem nenhuma reserva ainda"
                        subtitle={
                            userRole === "contractor"
                                ? "Explore nossa seleção de artistas talentosos e faça sua primeira reserva para uma experiência inesquecível."
                                : "Aqui você poderá visualizar todas as suas reservas futuras. Fique atento às novas oportunidades!"
                        }
                    />
                ) : (
                    !isLoading && dataFetched && bookings?.map((booking) => (
                        <BookingScheduledCard
                            key={booking._id}
                            bookingID={booking._id}
                            artistID={booking.artist_auth0_sub_id}
                            contractorID={booking.contractor_auth0_sub_id}
                            period={booking.booking_period}
                            date={booking.booking_date}
                            bookingStatus={booking.booking_status}
                            bookingMembersNumber={booking.members_number}
                            bookingPrice={booking.price}
                            userPicture={userPicture}
                            userRole={userRole}
                            hasCommented={userRole === 'contractor' ? booking.contractor_has_commented : booking.artist_has_commented}
                            hasRated={userRole === 'contractor' ? booking.contractor_has_rated : booking.artist_has_rated}
                        />
                    ))
                )}
            </Box>
            <HeroFooter />
        </Box>
    );
}

export default BookingScheduled;
