import React from "react";
import { Box, Container, Grid, Typography, Link, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from '@mui/icons-material/X';

const HeroFooter = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 4,
        px: 2,
        backgroundColor: "#f9fafb",
        borderTop: "1px solid #e5e7eb",
        color: "text.secondary",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={3}>
            <Typography
              variant="h6"
              sx={{ color: "black", display: "flex", alignItems: "center" }}
            >
              <Box
                component="img"
                src="/cnxx.png"
                alt="Logo"
                sx={{ width: 35, height: 30, marginRight: 1 }}
              />
              CONEXXA
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, color: "text.secondary", textAlign: "left" }}>
              A plataforma que conecta seu negócio à música.
            </Typography>
          </Grid>

          <Grid item xs={6} sm={2} sx={{ textAlign: "left" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
              SOBRE
            </Typography>
            <Link href="#" color="inherit" underline="hover" display="block" sx={{ mb: 1 }}>
              A plataforma Conexxa
            </Link>
            <Link href="#" color="inherit" underline="hover" display="block" sx={{ mb: 1 }}>
              Como Funciona
            </Link>
          </Grid>

          <Grid item xs={6} sm={2} sx={{ textAlign: "left" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
              REDES SOCIAIS
            </Typography>
            <Link href="#" color="inherit" underline="hover" display="block" sx={{ mb: 1 }}>
              Instagram
            </Link>
            <Link href="#" color="inherit" underline="hover" display="block" sx={{ mb: 1 }}>
              X (Twitter)
            </Link>
            <Link href="#" color="inherit" underline="hover" display="block" sx={{ mb: 1 }}>
              Facebook
            </Link>
          </Grid>

          <Grid item xs={6} sm={2} sx={{ textAlign: "left" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
              LEGAL
            </Typography>
            <Link href="#" color="inherit" underline="hover" display="block" sx={{ mb: 1 }}>
              Política de Privacidade
            </Link>
            <Link href="#" color="inherit" underline="hover" display="block" sx={{ mb: 1 }}>
              Termos & Condições
            </Link>
            <Link href="#" color="inherit" underline="hover" display="block" sx={{ mb: 1 }}>
              Política de Uso e Armazenamento de Dados
            </Link>
          </Grid>
        </Grid>

        <Box sx={{ borderTop: "1px solid #e5e7eb", my: 4 }} />

        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          <IconButton href="#" color="inherit">
            <FacebookIcon fontSize="medium" />
          </IconButton>
          <IconButton href="#" color="inherit">
            <InstagramIcon fontSize="medium" />
          </IconButton>
          <IconButton href="#" color="inherit">
            <XIcon fontSize="medium" />
          </IconButton>
        </Box>

        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 3 }}
        >
          © {new Date().getFullYear()} Conexxa™
        </Typography>
      </Container>
    </Box>
  );
}

export default HeroFooter;
