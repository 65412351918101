import {
    Box,
    Typography
} from "@mui/material";
import LineDividers from "../utils/LineDivider";
import {
    useState,
    memo
} from "react";
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

const Comment = ({ commentary }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const commentToDisplay = isExpanded
        ? commentary.comment
        : commentary.comment.length > 300
            ? commentary.comment.slice(0, 300) + '...'
            : commentary.comment;

    return (
        <Box
            sx={{
                border: '1px solid lightgray',
                borderRadius: '8px',
                padding: '16px',
                marginBottom: '16px',
                backgroundColor: 'white',
                textAlign: 'left',
            }}
        >
            <Typography variant="body1" fontWeight="bold">
                {commentary.nickname}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {format(new Date(commentary.created_at), 'dd \'de\' MMMM \'de\' yyyy', { locale: pt })}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '8px' }}>
                {commentToDisplay}
                {commentary.comment.length > 300 && !isExpanded && (
                    <span
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => setIsExpanded(true)}
                    >
                        {' '}
                        <span style={{ color: '#614ED9' }}>Ler mais</span>
                    </span>
                )}
                {isExpanded && (
                    <span
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => setIsExpanded(false)}
                    >
                        {' '}
                        <span style={{ color: '#614ED9' }}>Ler menos</span>
                    </span>
                )}
            </Typography>
        </Box>
    );
};

const CardDetailsComments = memo(({ actionUserComments, artist }) => {
    return (
        <>
            {actionUserComments === 'showArtistComments' && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        ml: 0,
                        mt: 4
                    }}>
                    <Typography>
                        ({artist.commentaries.length}) comentários
                    </Typography>
                </Box>
            )}


            <LineDividers />

            <div>
                {artist?.commentaries?.map((commentary, index) => (
                    <Comment key={index} commentary={commentary} />
                ))}
            </div>
        </>
    );
});


export default CardDetailsComments;
