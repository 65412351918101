import QuiltedImageList from '../utils/QuiltedImageList';
import { Box, Typography, useMediaQuery, Tooltip, Rating, Paper, IconButton, Button, ClickAwayListener, Grid, AppBar, Toolbar, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LineDivider from '../utils/LineDivider';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import NightlifeOutlinedIcon from '@mui/icons-material/NightlifeOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import CardDetailsComments from '../comment/CardDetailsComments';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import FilterBar from '../utils/FilterBar';
import HeroFooter from '../HeroFooter';
import AnaliticsService from '../../analiticsService';

const CardDetails = ({
  userRole,
  userPicture
}

) => {
  const navigate = useNavigate();
  const {
    id: artistID,
    artist_profile_selected,
    artist_country_code,
    artist_province_code,
    artist_city,
    artist_zone,
    artist_music_style,
    artist_period,
    artist_date
  } = useParams();
  const artistProfileSelected = artist_profile_selected;
  const artistProfileSelectedLabel = artistProfileSelected === 'band' ? 'banda' :
    artistProfileSelected === 'musician' ? 'músico' :
      artistProfileSelected === 'dj' && 'dj';
  const countryCode = artist_country_code;
  const provinceCode = artist_province_code;
  const city = artist_city;
  const zone = artist_zone;
  const musicStyle = artist_music_style;
  const period = artist_period;
  const date = new Date(artist_date);
  const formattedDate = date.toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
  const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;
  const [artist, setArtist] = useState();
  const [openTooltipPrice, setOpenTooltipPrice] = useState(false);
  const [openTooltipTop5, setOpenTooltipTop5] = useState(false);
  const [openTooltipArtistProfileInfo, setOpenTooltipArtistProfileInfo] = useState(false);
  const [openTooltipArtistType, setOpenTooltipArtistType] = useState(false);
  const [openTooltipMusicStyle, setOpenTooltipMusicStyle] = useState(false);
  const [openTooltipPeriod, setOpenTooltipPeriod] = useState(false);
  const [openTooltipMembers, setOpenTooltipMembers] = useState(false);
  const [openTooltipComments, setOpenTooltipComments] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  const [visitor, setVisitor] = useState();
  const [artistProfileTitleLabel, setArtistProfileTitleLabel] = useState("");
  const [artistDescriptionTitleLabel, setArtistDescriptionTitleLabel] = useState("");
  const appBarHeight = 86;
  const actionUserComments = 'showArtistComments';


  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleTooltipPriceClose = () => {
    setOpenTooltipPrice(false);
  };

  const handleTooltipPriceOpen = () => {
    setOpenTooltipPrice(true);
  };

  const handleTooltipTop5Close = () => {
    setOpenTooltipTop5(false);
  };

  const handleTooltipTop5Open = () => {
    setOpenTooltipTop5(true);
  };

  const handleTooltipArtistProfileInfoClose = () => {
    setOpenTooltipArtistProfileInfo(false);
  };

  const handleTooltipArtistProfileInfoOpen = () => {
    setOpenTooltipArtistProfileInfo(true);
  };

  const handleTooltipArtistTypeClose = () => {
    setOpenTooltipArtistType(false);
  };

  const handleTooltipArtistTypeOpen = () => {
    setOpenTooltipArtistType(true);
  };

  const handleTooltipMusicStyleClose = () => {
    setOpenTooltipMusicStyle(false);
  };

  const handleTooltipMusicStyleOpen = () => {
    setOpenTooltipMusicStyle(true);
  };

  const handleTooltipPeriodClose = () => {
    setOpenTooltipPeriod(false);
  };

  const handleTooltipPeridoOpen = () => {
    setOpenTooltipPeriod(true);
  };

  const handleTooltipMemberClose = () => {
    setOpenTooltipMembers(false);
  };

  const handleTooltipMemberOpen = () => {
    setOpenTooltipMembers(true);
  };

  const handleTooltipCommentsClose = () => {
    setOpenTooltipComments(false);
  };

  const handleTooltipCommentsOpen = () => {
    setOpenTooltipComments(true);
  };

  const calculateWidth = (baseChValue) => {
    const chInPixels = 8;
    const baseWidthInPixels = baseChValue * chInPixels;
    const adjustedWidthInPixels = baseWidthInPixels - (screenWidth * 0.1);
    const adjustedWidthInCh = adjustedWidthInPixels / chInPixels;
    return `${adjustedWidthInCh}ch`;
  };

  useEffect(() => {
    const fetchUserArtist = async () => {
      try {
        const response = await fetch(
          `${railwayAPIURL}/api/v1/profile/getuser?auth0_sub_id=${artistID}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'authKey': apiAuthKey,
            },
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch user artist');
        }
        const result = await response.json();
        const artist = result.user;

        setArtist(artist);
      } catch (error) {
        console.error('Error fetching user artist:', error);
      }
    };
    fetchUserArtist();
  }, [railwayAPIURL, apiAuthKey, artistID]);

  useEffect(() => {
    const fetchUserVisitor = async () => {
      if (!user || !isAuthenticated || !railwayAPIURL || !apiAuthKey) return;

      try {
        const response = await fetch(`${railwayAPIURL}/api/v1/profile/getuser?auth0_sub_id=${user.sub}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'authKey': apiAuthKey,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData?.error || response.statusText;
          throw new Error(`Failed to fetch user visitor: ${errorMessage}`);
        }

        const result = await response.json();
        const visitor = result.user;
        setVisitor(visitor);

        if (visitor?.favorites) {
          setIsFavorite(visitor.favorites.includes(artistID));
        }
      } catch (error) {
        console.error('Error fetching user visitor:', error);
      }
    };

    fetchUserVisitor();
  }, [railwayAPIURL, apiAuthKey, user, isAuthenticated, artistID]);

  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const handleFavoriteUser = async (favoriteStatus) => {
    if (isLoading) return;

    setIsLoading(true);
    setError(null);

    const data = JSON.stringify({
      "auth0_sub_id": user.sub,
      "auth0_sub_id_favorited_use": artist.auth0_sub_id,
    });

    try {
      const response = await fetch(
        `${railwayAPIURL}/api/v1/profile/favoriteuser`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'authKey': apiAuthKey,
          },
          body: data,
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update favorite status');
      }

      setIsFavorite(favoriteStatus);
    } catch (error) {
      console.error('Error updating favorite status:', error);
      setError(error.message);
      setIsFavorite(!favoriteStatus);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedToggleFavorite = debounce(async () => {
    if (isLoading) return;

    const newStatus = !isFavorite;

    setIsFavorite(newStatus);

    await handleFavoriteUser(newStatus);
  }, 300);

  const renderFavoriteButton = () => {
    if (!visitor) return <></>;
    return (
      <span>
        <IconButton
          onClick={debouncedToggleFavorite}
          disabled={isLoading}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
            },
            position: 'relative',
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                color: '#614ED9',
                position: 'absolute',
                ml: 6
              }}
            />
          ) : isFavorite ? (
            <FavoriteOutlinedIcon
              fontSize="large"
              sx={{
                color: '#614ED9',
                ml: 1,
                mr: 1,
              }}
            />
          ) : (
            <FavoriteBorderOutlinedIcon
              fontSize="large"
              sx={{
                color: '#614ED9',
                ml: 1,
                mr: 1,
              }}
            />
          )}
        </IconButton>
      </span>
    );
  };


  useEffect(() => {
    if (artist) {
      const newArtistProfileTitleLabel = `Perfil ${artist.artist_type.includes("band") ? "da banda" :
        artist.artist_type.includes("musician") ? "do músico" :
          artist.artist_type.includes("dj") && "do DJ"
        }`;
      const newArtistDescriptionTitleLabel = `Conheça um pouco mais  ${artist.artist_type.includes("band") ? "da banda" :
        artist.artist_type.includes("musician") ? "do músico" :
          artist.artist_type.includes("dj") && "do DJ"
        }`;
      setArtistProfileTitleLabel(newArtistProfileTitleLabel);
      setArtistDescriptionTitleLabel(newArtistDescriptionTitleLabel);
    }
  }, [artist, setArtistProfileTitleLabel, setArtistDescriptionTitleLabel]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (

    <>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          mt: 8
        }}>

        <Box sx={{ flex: 1, mx: isMobile ? 5 : 10 }}>
          {artistProfileSelectedLabel && countryCode && provinceCode && city && zone && musicStyle && period && formattedDate && (
            <FilterBar
              artistProfileSelectedLabel={artistProfileSelectedLabel}
              countryCode={countryCode}
              provinceCode={provinceCode}
              city={city}
              zone={zone}
              musicStyle={musicStyle}
              period={period}
              formattedDate={formattedDate}
            />
          )}

          <Box component="form" sx={{ '& .MuiTextField-root': { width: isMobile ? calculateWidth(35) : '55ch', mt: 2 }, paddingBottom: '10px' }} noValidate autoComplete="off">

            {artist && artist.artist_pictures && <QuiltedImageList imageUrls={artist.artist_pictures} />}

            <Grid container spacing={2} alignItems="center" mt={-5}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: isMobile ? 5 : 1
                  }}
                >
                  {artist && artist.rating >= 4.8 && (
                    <>
                      {!isMobile ? (
                        <Tooltip title={
                          <Box>
                            <Typography fontWeight="bold">Pertence ao Top 5 artistas</Typography>
                            <Typography>Este artista se destaca por ter uma boa qualificação no oferecimento de serviços e comentários positivos.</Typography>
                          </Box>
                        }
                          arrow placement="bottom">

                          <LocalActivityIcon
                            fontSize="large"
                            sx={{
                              color: '#FFB400',
                              mr: 1,
                              mt: isMobile ? 0 : 4
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <ClickAwayListener onClickAway={handleTooltipTop5Close}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipTop5Close}
                            open={openTooltipTop5}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <Box>
                                <Typography fontWeight="bold">Pertence ao Top 5 artistas</Typography>
                                <Typography>Este artista se destaca por ter uma boa qualificação no oferecimento de serviços e comentários positivos.</Typography>
                              </Box>
                            }

                          >
                            <IconButton
                              size="small"

                              onClick={handleTooltipTop5Open}
                            >
                              <LocalActivityIcon fontSize="large" sx={{ color: '#FFB400', mr: 1 }} />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                      )}
                    </>

                  )}
                  <Typography variant="h4"
                    sx={{
                      textAlign: isMobile ? 'center' : 'left',
                      mt: isMobile ? 0 : 4
                    }}
                  >
                    {artist && artist.artist_name}
                  </Typography>

                  {userRole === 'contractor' && (
                    <Tooltip
                      sx={{ ml: 2 }}
                      title={
                        <Box>
                          <Typography>
                            {isLoading
                              ? "Atualizando..."
                              : error
                                ? "Erro ao atualizar. Tente novamente."
                                : isFavorite
                                  ? "Clique para remover dos seus favoritos"
                                  : "Clique para salvar este artista aos seus favoritos"
                            }
                          </Typography>
                        </Box>
                      }
                      arrow
                      placement="bottom"
                    >
                      {renderFavoriteButton()}
                    </Tooltip>
                  )}
                </Box>

                {artist && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <Rating sx={{ mr: 1 }} name="read-only" value={Math.floor(artist.rating)} readOnly />
                    <Typography>({artist.rating.toFixed(1)})</Typography>
                  </Box>
                )}
              </Grid>
              {!isMobile && (
                <>
                  {(artist && isAuthenticated && userRole === 'contractor') ? (
                    <Grid item xs={12} md={6}>
                      <Paper
                        elevation={3}
                        sx={{
                          padding: 2,
                          textAlign: 'center',
                          maxWidth: '280px',
                          ml: 'auto',
                          mr: 4,
                          mt: isMobile ? 5 : -1,
                          position: 'relative',
                          zIndex: 1,
                          width: 320
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                          <Typography>
                            R$
                          </Typography>
                          <Typography variant="h5" fontWeight="bold" color="text.primary"
                            sx={{
                              ml: 1
                            }}
                          >
                            {artist && artist.price}
                          </Typography>
                          <ClickAwayListener onClickAway={handleTooltipPriceClose}>
                            <Tooltip
                              PopperProps={{ disablePortal: true }}
                              onClose={handleTooltipPriceClose}
                              open={openTooltipPrice}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Este valor é uma estimativa baseada nos serviços e custos do artista (incluindo produção, despesas e deslocamento). O preço final pode variar dependendo dos detalhes específicos do evento."
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  backgroundColor: 'white',
                                  padding: '1px',
                                  ml: 3,
                                  '&:hover': {
                                    backgroundColor: 'lightgray',
                                  },
                                }}
                                onClick={handleTooltipPriceOpen}
                              >
                                <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                              </IconButton>

                            </Tooltip>
                          </ClickAwayListener>
                        </Box>

                        <Typography variant="body2" color="text.secondary"
                          sx={{
                            mt: 1,
                            textAlign: 'left',
                            ml: 2
                          }}
                        >
                          Impostos, taxas e encargos inclusos
                        </Typography>


                        <Button
                          variant="contained"
                          sx={{
                            mt: 2,
                            backgroundColor: '#614ED9',
                            '&:hover': {
                              backgroundColor: '#513ec9',
                            },
                          }}
                          onClick={() => navigate(`/booking/${artist.auth0_sub_id}`, { state: { artist, period, date } })}
                        >
                          Ver reserva
                        </Button>

                      </Paper>
                    </Grid>
                  ) : null}
                </>
              )}
            </Grid>

            <LineDivider />

            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2, mt: 1 }}>
              <Typography variant="h5" sx={{ textAlign: 'left' }}>
                {artistProfileTitleLabel}
              </Typography>

              <ClickAwayListener onClickAway={handleTooltipArtistProfileInfoClose}>
                <Tooltip
                  PopperProps={{ disablePortal: true }}
                  onClose={handleTooltipArtistProfileInfoClose}
                  open={openTooltipArtistProfileInfo}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Itens do perfil do artista"
                >
                  <IconButton
                    size="small"
                    onClick={handleTooltipArtistProfileInfoOpen}
                    sx={{ ml: 1 }}
                  >
                    <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                  </IconButton>
                </Tooltip>
              </ClickAwayListener>
            </Box>

                  {(isAuthenticated && userRole === 'contractor') || userRole === undefined || !isAuthenticated ? (
                    <Button
                      variant="contained"
                      sx={{
                        mt: 2,
                        backgroundColor: '#614ED9',
                        '&:hover': {
                          backgroundColor: '#513ec9',
                        },
                      }}
                      onClick={() => {
                        navigate(`/booking/${artist.auth0_sub_id}`, { state: { artist } });
                        AnaliticsService.sendEvent(AnaliticsService.EventTypes.DidClickBook);
                      }
                    }
                    >
                      Reservar
                    </Button>
                  ) : null}

            {artist && (
              <>
                <Stack direction="row"
                  sx={{
                    mt: 3,
                    ml: 2,
                    mb: 3
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        {!isMobile ? (
                          <>
                            <Tooltip title={
                              <Box>
                                <Typography fontWeight="bold">Perfil do artista</Typography>
                                <Typography>Descrição do perfil do artista.</Typography>
                              </Box>
                            }
                              arrow placement="bottom">

                              <Person4OutlinedIcon fontSize="large" sx={{ color: '#2E263DE6' }} />
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <ClickAwayListener onClickAway={handleTooltipArtistTypeClose}>
                              <Tooltip
                                PopperProps={{ disablePortal: true }}
                                onClose={handleTooltipArtistTypeClose}
                                open={openTooltipArtistType}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Descrição do perfil do artista"
                              >
                                <IconButton
                                  size="small"
                                  onClick={handleTooltipArtistTypeOpen}
                                  sx={{ ml: 1 }}
                                >
                                  <Person4OutlinedIcon fontSize="large" sx={{ color: '#2E263DE6' }} />
                                </IconButton>
                              </Tooltip>
                            </ClickAwayListener>
                          </>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography >
                          {
                            artist.artist_type === 'band' ? 'banda' :
                              artist.artist_type === 'musician' ? 'músico' :
                                artist.artist_type === 'dj' ? 'dj' :
                                  artist.artist_type
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: 3 }}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        {!isMobile ? (
                          <>
                            <Tooltip
                              title={
                                <Box>
                                  <Typography fontWeight="bold">Estilo musical</Typography>
                                  <Typography>Lista do(s) estilo(s) musical(is) do artista.</Typography>
                                </Box>
                              }
                              arrow
                              placement="bottom"
                            >
                              <NightlifeOutlinedIcon fontSize="large" sx={{ color: '#2E263DE6' }} />
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <ClickAwayListener onClickAway={handleTooltipMusicStyleClose}>
                              <Tooltip
                                PopperProps={{ disablePortal: true }}
                                onClose={handleTooltipMusicStyleClose}
                                open={openTooltipMusicStyle}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Lista do(s) estilo(s) musical(is) do artista"
                              >
                                <IconButton
                                  size="small"
                                  onClick={handleTooltipMusicStyleOpen}
                                  sx={{ ml: 1 }}
                                >
                                  <NightlifeOutlinedIcon fontSize="large" sx={{ color: '#2E263DE6' }} />
                                </IconButton>
                              </Tooltip>
                            </ClickAwayListener>
                          </>
                        )}
                      </Grid>
                      <Grid item>
                        {artist.music_styles && artist.music_styles.map((style, index) => (
                          <Typography key={index} display="inline" sx={{ display: 'inline' }}>
                            {style}{(index + 1) % 3 === 0 ? <br /> : ', '}
                          </Typography>
                        ))}
                      </Grid>
                    </Grid>
                  </Box>



                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: 3 }}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        {!isMobile ? (
                          <>
                            <Tooltip title={
                              <Box>
                                <Typography fontWeight="bold">Horários</Typography>
                                <Typography>Lista do(s) horário(s) de atuação do artista.</Typography>
                              </Box>
                            }
                              arrow placement="bottom">

                              <AccessTimeOutlinedIcon fontSize="large" sx={{ color: '#2E263DE6' }} />
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <ClickAwayListener onClickAway={handleTooltipPeriodClose}>
                              <Tooltip
                                PopperProps={{ disablePortal: true }}
                                onClose={handleTooltipPeriodClose}
                                open={openTooltipPeriod}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Lista do(s) horário(s) de atuação do artista"
                              >
                                <IconButton
                                  size="small"
                                  onClick={handleTooltipPeridoOpen}
                                  sx={{ ml: 1 }}
                                >
                                  <AccessTimeOutlinedIcon fontSize="large" sx={{ color: '#2E263DE6' }} />
                                </IconButton>
                              </Tooltip>
                            </ClickAwayListener>
                          </>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography >
                          {artist.period && artist.period.join(', ')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: 3 }}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        {!isMobile ? (
                          <>
                            <Tooltip title={
                              <Box>
                                <Typography fontWeight="bold">Número de integrantes</Typography>
                                <Typography>Quantidade de pessoas que integram o grupo .</Typography>
                              </Box>
                            }
                              arrow placement="bottom">

                              <Groups2OutlinedIcon fontSize="large" sx={{ color: '#2E263DE6' }} />
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <ClickAwayListener onClickAway={handleTooltipMemberClose}>
                              <Tooltip
                                PopperProps={{ disablePortal: true }}
                                onClose={handleTooltipMemberClose}
                                open={openTooltipMembers}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Quantidade de pessoas que integram o grupo"
                              >
                                <IconButton
                                  size="small"
                                  onClick={handleTooltipMemberOpen}
                                  sx={{ ml: 1 }}
                                >
                                  <Groups2OutlinedIcon fontSize="large" sx={{ color: '#2E263DE6' }} />
                                </IconButton>
                              </Tooltip>
                            </ClickAwayListener>
                          </>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography >
                          {artist.members_number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>

              </>
            )}
            <LineDivider />

            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2, mt: 3 }}>
              <Typography variant="h5" sx={{ textAlign: 'left' }}>
                {artistDescriptionTitleLabel}
              </Typography>

            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2, mt: 1, mb: 4 }}>
              {artist && (
                <>
                  <Typography variant="body1"
                    sx={{
                      textAlign: 'justify',
                      mt: 2
                    }}>
                    {artist.artist_description}
                  </Typography>
                </>
              )}
            </Box>
          </Box>

          <LineDivider />

          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2, mt: 2, mb: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" sx={{ textAlign: 'left' }}>
                O que dizem os comentários
              </Typography>
              <ClickAwayListener onClickAway={handleTooltipCommentsClose}>
                <Tooltip
                  PopperProps={{ disablePortal: true }}
                  onClose={handleTooltipCommentsClose}
                  open={openTooltipComments}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Os comentários são de usuários que realizaram reservas através da nossa plataforma, garantindo que você tenha acesso apenas a experiências genuínas!"
                >
                  <IconButton
                    size="small"
                    sx={{
                      backgroundColor: 'white',
                      padding: '1px',
                      mr: 3,
                      '&:hover': {
                        backgroundColor: 'lightgray',
                      },
                      ml: !isMobile && 2
                    }}
                    onClick={handleTooltipCommentsOpen}
                  >
                    <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                  </IconButton>

                </Tooltip>
              </ClickAwayListener>
            </Box>
            <Box sx={{ width: '100%', marginBottom: '100px' }}>
              {artist && (
                <CardDetailsComments
                  actionUserComments={actionUserComments}
                  artist={artist}
                  isAuthenticated={isAuthenticated}
                  userPicture={userPicture}
                />
              )}
            </Box>
          </Box>

        </Box>
        <HeroFooter />
      </Box >

      {
        isMobile && (
          <>
            {(artist && isAuthenticated && userRole === 'contractor') ? (
              <AppBar
                position="fixed"
                sx={{
                  top: 'auto',
                  bottom: 0,
                  backgroundColor: '#F5F5F5',
                  height: appBarHeight,

                }}
              >

                <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                  <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>


                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mr: 1
                        }}
                      >
                        R$
                      </Typography>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{
                          ml: 0,
                        }}
                      >
                        {artist && artist.price}
                      </Typography>
                      <ClickAwayListener onClickAway={handleTooltipPriceClose}>
                        <Tooltip
                          PopperProps={{ disablePortal: true }}
                          onClose={handleTooltipPriceClose}
                          open={openTooltipPrice}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Este valor é uma estimativa baseada nos serviços e custos do artista (incluindo produção, despesas e deslocamento). O preço final pode variar dependendo dos detalhes específicos do evento."
                        >
                          <IconButton
                            size="small"

                            onClick={handleTooltipPriceOpen}
                          >
                            <InfoOutlinedIcon fontSize="small" sx={{ color: '#614ED9' }} />
                          </IconButton>
                        </Tooltip>
                      </ClickAwayListener>
                    </Box>

                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        mt: 1,
                        textAlign: 'left',
                      }}
                    >
                      Impostos, taxas e encargos inclusos
                    </Typography>
                  </Box>


                  <Button
                    variant="contained"
                    sx={{
                      ml: 4,
                      mt: 2,
                      mb: 2,
                      backgroundColor: '#614ED9',
                      whiteSpace: 'nowrap',
                      '&:hover': {
                        backgroundColor: '#513ec9',
                      },
                    }}
                    onClick={() => navigate(`/booking/${artist.auth0_sub_id}`, { state: { artist, period, date } })}
                  >
                    Ver reserva
                  </Button>

                </Toolbar>
              </AppBar>
            ) : null}
          </>
        )
      }

    </>

  );
};

export default CardDetails;
